import { IntlShape } from 'react-intl'
import Analytics from 'common/analytics/Analytics'
import { CAMPAIGN_FORMAT_FIELD_VALUES } from 'common/campaignHelpers'
import { areFormFieldsMissing } from 'common/formikUtils'
import { SchemaType } from 'common/validation'
import { AuthCtx, isProviAccount } from 'context'
import { GoalFormat } from 'pages/CampaignCreate/components/CampaignCreateForm/types'
import { getUniqueLabelStrings, REJECTED_DECISION } from 'pages/DisplayProduct/Review/utils'
import { ApprovalInfo } from 'pages/DisplayProduct/types'
import { AdminDisplayAdGroupResponseDataAttributesCreativeTypeEnum as CreativeTypeEnum } from 'service/openapi/__codegen__/models/AdminDisplayAdGroupResponseDataAttributesCreative'
import { DisplayAdGroupFormData, creativeAssetsSchema } from '../../displayAdGroup.types'
import { conditionallyRequiredFormFields, getRequiredFormFields } from '../../draftState.utils'

export const buildRejectionLabelStringFromES = (
  fieldEditorialState: ApprovalInfo | undefined,
  intl: IntlShape
): string | undefined => {
  const isRejected = fieldEditorialState?.decision === REJECTED_DECISION
  if (!isRejected) return undefined

  const rejectionLabels = fieldEditorialState?.labels || []
  const uniqueLabelStrings = getUniqueLabelStrings(rejectionLabels, intl)

  return uniqueLabelStrings
}

export const shouldSkipNonEmptyValidation = (
  values: DisplayAdGroupFormData,
  skipNonEmptyValidation: boolean
) => {
  const requiredFormFields = getRequiredFormFields(values.creativeType)

  return (
    areFormFieldsMissing(values, requiredFormFields, conditionallyRequiredFormFields) &&
    skipNonEmptyValidation
  )
}

export const trackKeywordRecommendationsEvent = (
  actionType: string,
  payload: Record<string, unknown>
) => {
  Analytics.track(`recommendations.display.keywords.${actionType}`, payload)
}

export const getCreativeTypeFromGoalFormat = (
  authCtx: AuthCtx,
  goalFormat?: GoalFormat
): CreativeTypeEnum | undefined => {
  const proviAccount = isProviAccount(authCtx)

  switch (goalFormat) {
    case CAMPAIGN_FORMAT_FIELD_VALUES.displayBanner:
      return CreativeTypeEnum.SearchBannerV1
    case CAMPAIGN_FORMAT_FIELD_VALUES.shoppableDisplay:
      if (proviAccount) {
        return CreativeTypeEnum.PromotedAisleV4
      }
      return CreativeTypeEnum.PromotedAisleV1
    case CAMPAIGN_FORMAT_FIELD_VALUES.shoppableVideo:
      if (proviAccount) {
        return CreativeTypeEnum.ShoppableVideoV3
      }
      return CreativeTypeEnum.ShoppableVideoV1
    case CAMPAIGN_FORMAT_FIELD_VALUES.shoppableDisplayRecipe:
      return CreativeTypeEnum.SponsoredRecipeV1
    case CAMPAIGN_FORMAT_FIELD_VALUES.shoppableVideoRecipe:
      return CreativeTypeEnum.SponsoredRecipeVideoV1
    case CAMPAIGN_FORMAT_FIELD_VALUES.displayBannerUrlClick:
      return CreativeTypeEnum.ImageBannerV3
    case CAMPAIGN_FORMAT_FIELD_VALUES.shoppableDisplayUrlClick:
      return CreativeTypeEnum.PromotedAisleV3
    case CAMPAIGN_FORMAT_FIELD_VALUES.shoppableVideoUrlClick:
      return CreativeTypeEnum.ShoppableVideoV2
    case CAMPAIGN_FORMAT_FIELD_VALUES.inspiration:
      return CreativeTypeEnum.OccasionV1
    default:
      return undefined
  }
}

export const isAnyCreativeCropping = (values: DisplayAdGroupFormData): boolean => {
  if (!values?.creative) return false

  return Object.keys(values.creative).some(creativeType => {
    const creativeAsset: SchemaType<typeof creativeAssetsSchema> = values.creative[creativeType]
    return creativeAsset?.urls?.isCropping === true
  })
}
