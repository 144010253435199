import { FormikValues } from 'formik'
import { trim } from 'lodash'
import { IntlShape } from 'react-intl'
import { ClientError, containsFieldError, getNexusApiErrorMessagesForParams } from 'common/utils'
import { NotificationType } from 'context'
import { MessageIdType } from 'locales/types'
import { LevelEnum } from 'pages/BrandPage/brandPage.types'
import {
  MAX_PRODUCTS_PC,
  MAX_PRODUCTS_PC_PILOT,
  MAX_TOTAL_PRODUCTS,
  MAX_TOTAL_PRODUCTS_PILOT,
  MIN_PRODUCTS,
  MIN_PRODUCTS_PILOT,
  MIN_PRODUCT_GROUPS,
  MAX_TOTAL_PRODUCTS_GROUPS,
  MAX_PRODUCT_GROUPS_PC,
  ENABLED_VALIDATION_ERROR_FIELD_KEY,
  FIELD_ERROR_LINKED_TO_ACTIVE_AD_GROUP,
} from './constants'

export { GetBrandPagesParamData as BrandPage } from 'service/openapi/__codegen__/models/GetBrandPagesParamData'

const DISALLOWED_CHARACTERS = /[^a-z0-9\s\-]+/g
const CONSECUTIVE_DELIMITER_CHARACTERS = /[\s\-]+/g
const WORD_DELIMITER = '-'

const HTTPS_SCHEME_PREFIX = 'https://'

type Defined<T> = T extends undefined ? never : T

export function isDefined<T>(value: T): value is Defined<T> {
  return value !== undefined
}

export const pathForBrandInput = (hierarchy: (string | undefined)[]): string => {
  return urlSafeHierarchy(hierarchy).join('/')
}

function urlSafeHierarchy(hierarchy: (string | undefined)[]): string[] {
  return hierarchy.filter(isDefined).map(urlSafeSegment)
}

export function urlSafeSegment(segment: string): string {
  return trim(
    segment
      .toLowerCase()
      .replace(DISALLOWED_CHARACTERS, '')
      .replace(CONSECUTIVE_DELIMITER_CHARACTERS, WORD_DELIMITER),
    WORD_DELIMITER
  )
}

export function brandInputFields(level: LevelEnum, isSeasonal: boolean) {
  const fields: { label: MessageIdType; hint?: MessageIdType }[] = []

  switch (level) {
    case LevelEnum.Brand:
      fields.push({ label: 'pages.brandPages.field.hierarchy.brand.label' })
      break
    case LevelEnum.SubBrand:
      fields.push(
        { label: 'pages.brandPages.field.hierarchy.brand.label' },
        { label: 'pages.brandPages.field.hierarchy.subBrand.label' }
      )
      break
    case LevelEnum.Parent:
      fields.push(
        {
          label: 'pages.brandPages.field.hierarchy.parent.label',
          hint: 'pages.brandPages.field.hierarchy.parent.hint',
        },
        { label: 'pages.brandPages.field.hierarchy.custom.label' }
      )
      break
  }

  if (isSeasonal) {
    fields.push({
      label: 'pages.brandPages.field.hierarchy.seasonal.label',
      hint: 'pages.brandPages.field.hierarchy.seasonal.hint',
    })
  }

  return fields
}

const getInstacartDomain = (countryId?: number) => {
  switch (countryId) {
    case 840:
      return 'com'
    case 124:
      return 'ca'
    default:
      return 'com'
  }
}

export function publicPageUrl(path: string, countryId?: number, includeScheme = false) {
  const domain = getInstacartDomain(countryId)
  return `${includeScheme ? HTTPS_SCHEME_PREFIX : ''}www.instacart.${domain}/store/brands/${path}`
}

export function presentationUrl(url: string) {
  return url.replace(HTTPS_SCHEME_PREFIX, '')
}

export function pageDocumentTitlePreview(hierarchy: string[]): string {
  const brandInput = hierarchy.join(' ')

  return `${brandInput} | Instacart`
}

export function removeBlock<T>(removeIndex: number, array: T[]): T[] {
  const currentBlocks = array.slice()
  const firstChunk = currentBlocks.slice(0, removeIndex)
  const secondChunk = currentBlocks.slice(removeIndex + 1)
  return firstChunk.concat(secondChunk)
}

export function moveBlock<T>(moveIndex: number, array: T[], down?: boolean): T[] {
  const currentBlocks = array.slice()
  const indexPlusOne = moveIndex + 1
  const indexMinusOne = moveIndex - 1

  const itemOne = currentBlocks[down ? indexPlusOne : moveIndex]
  const itemTwo = currentBlocks[down ? moveIndex : indexMinusOne]
  const firstChunk = currentBlocks.slice(0, down ? moveIndex : indexMinusOne)
  const secondChunk = currentBlocks.slice(down ? moveIndex + 2 : indexPlusOne)
  return firstChunk.concat([itemOne, itemTwo], secondChunk)
}

export function getUrlEncodedValues<T extends FormikValues, U extends keyof T & string>(
  values: T,
  fieldOrder: U[]
) {
  const populatedParams = fieldOrder
    .map(field => {
      const value = values[field]
      return [`ic_${field.toLowerCase()}`, value?.trim()]
    })
    .filter(([, value]) => Boolean(value))

  return new URLSearchParams(Object.fromEntries(populatedParams)).toString()
}

export const assetVal = (base: string) => `${base}.uploadedUri`

export function getProductLimit(
  isBrandPageProductIncreaseEnabled: boolean,
  isBrandPageBlockProductDecreaseEnabled: boolean,
  isProductGroupSelector = false
) {
  const maxProductsPc = isProductGroupSelector
    ? MAX_PRODUCT_GROUPS_PC
    : isBrandPageProductIncreaseEnabled
    ? MAX_PRODUCTS_PC_PILOT
    : MAX_PRODUCTS_PC
  const maxTotalProducts = isProductGroupSelector
    ? MAX_TOTAL_PRODUCTS_GROUPS
    : isBrandPageProductIncreaseEnabled
    ? MAX_TOTAL_PRODUCTS_PILOT
    : MAX_TOTAL_PRODUCTS
  const minProducts = isProductGroupSelector
    ? MIN_PRODUCT_GROUPS
    : isBrandPageBlockProductDecreaseEnabled
    ? MIN_PRODUCTS_PILOT
    : MIN_PRODUCTS

  return {
    maxProductsPc,
    maxTotalProducts,
    minProducts,
  }
}

export function pausePageErrorNotification(intl: IntlShape, err: ClientError) {
  const linkedToActiveAdGroup = containsFieldError(
    err as ClientError,
    ENABLED_VALIDATION_ERROR_FIELD_KEY,
    FIELD_ERROR_LINKED_TO_ACTIVE_AD_GROUP
  )
  const { otherErrors } = getNexusApiErrorMessagesForParams(intl, err as ClientError, [
    ENABLED_VALIDATION_ERROR_FIELD_KEY,
  ])

  const notificationOption = { type: NotificationType.ERROR }
  if (linkedToActiveAdGroup) {
    return {
      ...notificationOption,
      message: otherErrors,
    }
  }

  return {
    ...notificationOption,
    messageId: 'pages.brandPages.dashboard.notice.pausePage.failed.cta' as MessageIdType,
  }
}
