// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { DisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo } from './DisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo'
import {
  DisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo'
import type { DisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage } from './DisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage'
import {
  DisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage'
import type { DisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile } from './DisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile'
import {
  DisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile'
import type { DisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb } from './DisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb'
import {
  DisplayAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeAssetsImageWebToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb'
import type { DisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile } from './DisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile'
import {
  DisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile'
import type { DisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail } from './DisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail'
import {
  DisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail'
import type { DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile } from './DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile'
import {
  DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile'
import type { DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb } from './DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb'
import {
  DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb'
import type { DisplayAdGroupResponseDataAttributesCreativeAssetsVideo } from './DisplayAdGroupResponseDataAttributesCreativeAssetsVideo'
import {
  DisplayAdGroupResponseDataAttributesCreativeAssetsVideoFromJSON,
  DisplayAdGroupResponseDataAttributesCreativeAssetsVideoFromJSONTyped,
  DisplayAdGroupResponseDataAttributesCreativeAssetsVideoToJSON,
} from './DisplayAdGroupResponseDataAttributesCreativeAssetsVideo'

/**
 *
 * @export
 * @interface DisplayAdGroupResponseDataAttributesCreativeAssets
 */
export interface DisplayAdGroupResponseDataAttributesCreativeAssets {
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeAssets
   */
  topImageWeb?: DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeAssets
   */
  imageWeb?: DisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeAssets
   */
  imageMobile?: DisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeAssets
   */
  topImageMobile?: DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeAssets
   */
  squareImageMobile?: DisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeAssets
   */
  heroImage?: DisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeAssets
   */
  brandLogo?: DisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeAssetsVideo}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeAssets
   */
  video?: DisplayAdGroupResponseDataAttributesCreativeAssetsVideo
  /**
   *
   * @type {DisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail}
   * @memberof DisplayAdGroupResponseDataAttributesCreativeAssets
   */
  thumbnail?: DisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail
}

/**
 * Check if a given object implements the DisplayAdGroupResponseDataAttributesCreativeAssets interface.
 */
export function instanceOfDisplayAdGroupResponseDataAttributesCreativeAssets(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function DisplayAdGroupResponseDataAttributesCreativeAssetsFromJSON(
  json: any
): DisplayAdGroupResponseDataAttributesCreativeAssets {
  return DisplayAdGroupResponseDataAttributesCreativeAssetsFromJSONTyped(json, false)
}

export function DisplayAdGroupResponseDataAttributesCreativeAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DisplayAdGroupResponseDataAttributesCreativeAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    topImageWeb: !exists(json, 'top_image_web')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSON(
          json['top_image_web']
        ),
    imageWeb: !exists(json, 'image_web')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSON(json['image_web']),
    imageMobile: !exists(json, 'image_mobile')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSON(json['image_mobile']),
    topImageMobile: !exists(json, 'top_image_mobile')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSON(
          json['top_image_mobile']
        ),
    squareImageMobile: !exists(json, 'square_image_mobile')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileFromJSON(
          json['square_image_mobile']
        ),
    heroImage: !exists(json, 'hero_image')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSON(json['hero_image']),
    brandLogo: !exists(json, 'brand_logo')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSON(json['brand_logo']),
    video: !exists(json, 'video')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeAssetsVideoFromJSON(json['video']),
    thumbnail: !exists(json, 'thumbnail')
      ? undefined
      : DisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSON(json['thumbnail']),
  }
}

export function DisplayAdGroupResponseDataAttributesCreativeAssetsToJSON(
  value?: DisplayAdGroupResponseDataAttributesCreativeAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    top_image_web: DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebToJSON(
      value.topImageWeb
    ),
    image_web: DisplayAdGroupResponseDataAttributesCreativeAssetsImageWebToJSON(value.imageWeb),
    image_mobile: DisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileToJSON(
      value.imageMobile
    ),
    top_image_mobile: DisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileToJSON(
      value.topImageMobile
    ),
    square_image_mobile: DisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileToJSON(
      value.squareImageMobile
    ),
    hero_image: DisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageToJSON(value.heroImage),
    brand_logo: DisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoToJSON(value.brandLogo),
    video: DisplayAdGroupResponseDataAttributesCreativeAssetsVideoToJSON(value.video),
    thumbnail: DisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailToJSON(value.thumbnail),
  }
}
