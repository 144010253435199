/**
 * This file is reserved strictly for Flipper constants. They should be added here to be usable throughout ads-web
 */
export const FLIPPER_AB_TESTS_KILL_SWITCH_CHART = 'self_service_ui_ab_tests_kill_switch_chart'
export const FLIPPER_AB_TESTS_KILL_SWITCH_FILTERS = 'self_service_ui_ab_tests_kill_switch_filters'
export const FLIPPER_AB_TESTS_KILL_SWITCH_SUM_ROW = 'self_service_ui_ab_tests_kill_switch_sum_row'
export const FLIPPER_AB_TEST_CREATION = 'self_service_ui_ab_test_creation'
export const FLIPPER_AB_TEST_CREATION_ENABLED_FLIPPER = 'self_service_ui_ab_test_creation_enabled'
export const FLIPPER_ADMIN_TIER_MANAGEMENT = 'self_service_ui_admin_tier_management'
export const FLIPPER_ALLOW_PRIVATE_LABEL_ICIP_REPORTS =
  'self_service_ui_allow_private_label_icip_reports'
export const FLIPPER_AUTO_TARGETED_KEYWORD_REPORTING =
  'self_service_ui_auto_targeted_keyword_reporting'
export const FLIPPER_BID_INCREASE_PAGE_AVAILABLE = 'self_service_ui_bid_increase_experience'
export const FLIPPER_BID_LANDSCAPE_DISABLE = 'self_service_ui_bid_landscape_disable'
export const FLIPPER_BRAND_PAGE_ALLOW_EDITING_BRAND_AFTER_SERVING =
  'self_service_ui_brand_page_allow_editing_brand_after_serving'
export const FLIPPER_BRAND_PAGE_APPEAL = 'self_service_ui_brand_page_appeal'
export const FLIPPER_BRAND_PAGE_BLOCK_PRODUCT_DECREASE =
  'self_service_ui_brand_page_block_product_decrease'
export const FLIPPER_BRAND_PAGE_PRODUCT_INCREASE = 'self_service_ui_brand_page_product_increase'
export const FLIPPER_BRAND_PAGE_TEMPLATES = 'self_service_ui_brand_page_templates'
export const FLIPPER_BROAD_MATCH_REPORTING = 'self_service_ui_broad_match_reporting'
export const FLIPPER_BUDGET_PLANNING_TOOL = 'self_service_ui_budget_planning_tool'
export const FLIPPER_CANADA_SUB_CATEGORY = 'self_service_ui_canada_sub_category'
export const FLIPPER_CIP_CATALYST_BANNER = 'self_service_ui_cip_catalyst_banner'
export const FLIPPER_CIP_CATALYST_BANNER_FORCE_ON = 'self_service_ui_cip_catalyst_banner_force_on'
export const FLIPPER_CSAT_SURVEY = 'self_service_ui_csat_survey'
export const FLIPPER_CSAT_SURVEY_FORCE_TRIGGER = 'self_service_ui_csat_survey_force_trigger'
export const FLIPPER_CSAT_SURVEY_INSTACART_ACCOUNT = 'self_service_ui_csat_survey_instacart_account'
export const FLIPPER_CYP_ACCOUNT_REPORTING_ENTITY_MAPPING_ENFORCED =
  'self_service_ui_claim_your_products_account_reporting_entity_mapping_enforced'
export const FLIPPER_CYP_PRODUCT_MAPPING_REQUESTS_BULK =
  'self_service_ui_claim_your_products_product_mapping_requests_bulk'
export const FLIPPER_CYP_PRODUCT_MAPPING_REQUESTS_ENFORCED =
  'self_service_ui_claim_your_products_product_mapping_requests_enforced'
export const FLIPPER_DISABLE_BID_STRENGTH_ATTRIBUTED_SALES_SORTABLE_COLUMNS =
  'self_service_ui_disable_bid_strength_attributed_sales_sortable_columns'
export const FLIPPER_DISABLE_KEYWORD_TABLE_SORTABLE_COLUMNS =
  'self_service_ui_disable_keyword_table_sortable_columns'
export const FLIPPER_DISABLE_LINK_YOUR_BRAND = 'self_service_ui_disable_link_your_brand'
export const FLIPPER_DISABLE_MRC_MIN_DATE = 'self_service_ui_disable_mrc_min_date'
export const FLIPPER_DISABLE_RECOMMENDATIONS = 'self_service_ui_disable_bid_recommendations'
export const FLIPPER_DISABLE_SP_KW_SUM_ROW_KILLSWITCH =
  'self_service_ui_disable_sponsored_product_keyword_sum_row'
export const FLIPPER_DISABLE_TAXONOMY_UPDATE_TOOL =
  'self_service_ui_disable_taxonomy_internal_update_tool'
export const FLIPPER_DISABLE_TEST = 'disable_test_flipper'
export const FLIPPER_DISPLAY_AD_GROUPS_KILL_SWITCH_EXPORT =
  'self_service_ui_display_ad_groups_kill_switch_export'
export const FLIPPER_DISPLAY_AD_GROUP_OVERVIEW_KILL_SWITCH_EXPORT =
  'self_service_ui_display_ad_group_overview_kill_switch_export'
export const FLIPPER_DISPLAY_BRAND_PAGE_KILL_SWITCH_EXPORT =
  'self_service_ui_display_brand_page_kill_switch_export'
export const FLIPPER_DISPLAY_CAMPAIGNS_FRAPPUCCINO_REPORTING =
  'self_service_ui_display_campaigns_frappuccino_reporting'
export const FLIPPER_DISPLAY_CAMPAIGNS_KILL_SWITCH_EXPORT =
  'self_service_ui_display_campaigns_kill_switch_export'
export const FLIPPER_DISPLAY_DATA_EXPORTS = 'self_service_ui_display_data_exports'
export const FLIPPER_DISPLAY_INTERGRITY_APPEAL = 'self_service_ui_display_integrity_appeal'
export const FLIPPER_DISPLAY_IN_CANADA = 'self_service_ui_display_ads_canada'
export const FLIPPER_DISPLAY_KEYWORDS_KILL_SWITCH_EXPORT =
  'self_service_ui_display_keywords_kill_switch_export'
export const FLIPPER_DISPLAY_PRODUCTS_KILL_SWITCH_CHART =
  'self_service_ui_display_products_kill_switch_chart'
export const FLIPPER_DISPLAY_PRODUCTS_KILL_SWITCH_EXPORT =
  'self_service_ui_display_products_kill_switch_export'
export const FLIPPER_DISPLAY_PRODUCTS_KILL_SWITCH_FILTERS =
  'self_service_ui_display_products_kill_switch_filters'
export const FLIPPER_DISPLAY_PRODUCTS_KILL_SWITCH_SUM_ROW =
  'self_service_ui_display_products_kill_switch_sum_row'
export const FLIPPER_DISPLAY_SELECT_AD_GROUPS_FOR_REVIEW =
  'self_service_ui_display_select_ad_groups_for_review'
export const FLIPPER_DOGFOOD_ACCOUNTS = 'self_service_ui_dogfood_accounts'
export const FLIPPER_DUPLICATE_DISPLAY_CAMPAIGNS = 'self_service_ui_duplicate_display_campaigns'
export const FLIPPER_EMAIL_SALES_ANALYTICS = 'self_service_ui_email_sales_analytics'
export const FLIPPER_ENABLE_ADD_A_PROMOTION_MODAL = 'self_service_ui_add_a_promotion_sp_modal'
export const FLIPPER_ENABLE_ADD_OR_EDIT_ACCOUNT_MODAL_V2 =
  'self_service_ui_add_edit_account_modal_v2'
export const FLIPPER_ENABLE_ART_REPORTING = 'self_service_ui_art_reporting'
export const FLIPPER_ENABLE_BID_THRESHOLDS_CONFIRMATION_MODAL =
  'self_service_ui_bid_thresholds_confirmation_modal'
export const FLIPPER_ENABLE_BRANDED_KEYWORDS_OPT_OUT = 'self_service_ui_branded_keywords_opt_out'
export const FLIPPER_ENABLE_BRAND_EXPLORER_FASTEST_GROWING_BRANDS_2023 =
  'ads_be_enable_fastest_growing_brands_2023'
export const FLIPPER_ENABLE_BRAND_PAGES_FRAPPUCCINO_REPORTING =
  'ads_be_enable_brand_pages_frappuccino_reporting'
export const FLIPPER_ENABLE_CAMPAIGN_NEGATIVE_KEYWORDS =
  'self_service_ui_campaign_negative_keywords'
export const FLIPPER_ENABLE_CATALYST = 'self_service_ui_catalyst'
export const FLIPPER_ENABLE_CATALYST_KILLSWITCH = 'self_service_ui_catalyst_killswitch'
export const FLIPPER_ENABLE_CHANGE_HISTORY = 'self_service_ui_change_history'
export const FLIPPER_ENABLE_DEFAULT_BID_ENDPOINT_REFACTOR =
  'self_service_ui_default_bid_endpoint_refactor'
export const FLIPPER_ENABLE_DOGFOOD_ENABLED = 'self_service_ui_dogfood_display_approval_enabled'
export const FLIPPER_ENABLE_EBP = 'ads_be_enable_emerging_brand_portal'
export const FLIPPER_ENABLE_EBP_NUX = 'ads_be_enable_emerging_brand_portal_nux'
export const FLIPPER_ENABLE_EXPERIMENTAL_CAMPAIGN_TYPE_CREATION =
  'self_service_ui_experimental_campaign_type_creation'
export const FLIPPER_ENABLE_KEYWORD_BID_LANDSCAPE = 'self_service_ui_kw_landscape'
export const FLIPPER_ENABLE_MANUAL_TARGETING = 'self_service_ui_manual_targeting'
export const FLIPPER_ENABLE_MM_PLATFORM = 'self_service_ui_mm_platform'
export const FLIPPER_ENABLE_MM_PLATFORM_KW_TARGETING = 'self_service_ui_mm_platform_kw_targeting'
export const FLIPPER_ENABLE_MRC_UI = 'self_service_ui_mrc_ui'
export const FLIPPER_ENABLE_NTB_PROMOTION_SALES = 'ads_be_enable_ntb_promotion_sales'
export const FLIPPER_ENABLE_OBJECTIVE_BASED_BUYING_DEBUG_ENABLED = 'self_service_ui_obb_debug'
export const FLIPPER_ENABLE_OCCASIONS_PILOT = 'ads_core_occasions_pilot'
export const FLIPPER_ENABLE_PROMOTIONS_PRODUCT_TABLE =
  'self_service_ui_enable_promotions_product_table'
export const FLIPPER_ENABLE_PROVI_LAST_TOUCH_METRICS = 'self_service_ui_provi_lta_attribution'
export const FLIPPER_ENABLE_PROVI_VIDEO = 'self_service_ui_provi_video_enabled'
export const FLIPPER_ENABLE_RECIPES_OPEN_BETA = 'ads_core_recipes_open_beta'
export const FLIPPER_ENABLE_RECOMMENDATIONS_THRESHOLD = 'self_service_ui_bid_rec_guard_rails'
export const FLIPPER_ENABLE_RESERVATION_COPY_CAMPAIGNS_MENU =
  'self_service_ui_reservation_copy_campaigns_menu'
export const FLIPPER_ENABLE_RESERVATION_COPY_RESERVATIONS_MENU =
  'self_service_ui_reservation_copy_reservations_menu'
export const FLIPPER_ENABLE_ROAS_REC = 'self_service_ui_enable_automated_bidding_roas_suggestion'
export const FLIPPER_ENABLE_ROAS_REC_SALES = 'self_service_ui_opt_bidding_roas_rec_sales'
export const FLIPPER_ENABLE_ROAS_REC_SPEND = 'self_service_ui_opt_bidding_roas_rec_spend'
export const FLIPPER_ENABLE_SEPARATE_ALT_TEXT_FIELDS = 'self_service_ui_separate_alt_text_fields'
export const FLIPPER_ENABLE_SHOPPABLE_VIDEO_FULL_CHART_BAR =
  'self_service_ui_shoppable_video_beta_full_chart_bar'
export const FLIPPER_ENABLE_SHOPPABLE_VIDEO_LANDING_REPORTING =
  'self_service_ui_shoppable_video_beta_landing_reporting'
export const FLIPPER_ENABLE_SHOPPABLE_VIDEO_MRC_EXPORTS =
  'self_service_ui_shoppable_video_mrc_exports'
export const FLIPPER_ENABLE_SP_PRODUCT_TOGGLE = 'self_service_ui_enable_sp_product_toggle'
export const FLIPPER_ENABLE_VIDEO_COPY_CAMPAIGNS_MENU = 'self_service_ui_video_copy_campaigns_menu'
export const FLIPPER_EXPERIMENTAL_CAMPAIGNS_KILL_SWITCH_CHART =
  'self_service_ui_experimental_campaigns_reporting_kill_switch_chart'
export const FLIPPER_FRAAP_KILL_SWITCH_TEST_BREAKDOWNS =
  'self_service_ui_fraap_kill_switch_test_breakdowns'
export const FLIPPER_FRAAP_KILL_SWITCH_TEST_CHARTS = 'self_service_ui_fraap_kill_switch_test_charts'
export const FLIPPER_FRAAP_KILL_SWITCH_TEST_DATE_RANGE =
  'self_service_ui_fraap_kill_switch_test_date_range'
export const FLIPPER_FRAAP_KILL_SWITCH_TEST_DUMMY = 'self_service_ui_fraap_kill_switch_test_dummy'
export const FLIPPER_FRAAP_KILL_SWITCH_TEST_EXPORTS =
  'self_service_ui_fraap_kill_switch_test_exports'
export const FLIPPER_FRAAP_KILL_SWITCH_TEST_FILTERS =
  'self_service_ui_fraap_kill_switch_test_filters'
export const FLIPPER_FRAAP_KILL_SWITCH_TEST_SUM_ROW =
  'self_service_ui_fraap_kill_switch_test_sum_row'
export const FLIPPER_FRAAP_KILL_SWITCH_TEST_TABLES = 'self_service_ui_fraap_kill_switch_test_tables'
export const FLIPPER_FRAAP_MULTI_CHARTS = 'self_service_ui_multi_charts'
export const FLIPPER_FRAAP_SETTINGS_GEAR = 'self_service_ui_fraap_settings_gear'
export const FLIPPER_FRAP_UX_REFRESH = 'self_service_ui_frap_ux_refresh'
export const FLIPPER_FRAP_UX_REFRESH_PERSIST_DATE_RANGE =
  'self_service_ui_frap_ux_persist_date_range'
export const FLIPPER_HEADER_COUNTRY_INDICATOR = 'self_service_ui_header_country_indicator'
export const FLIPPER_HIDE_ADD_PRODUCTS_PRODUCT_LIBRARY =
  'self_service_ui_hide_add_products_product_library'
export const FLIPPER_IMAGE_CROPPING = 'self_service_ui_image_cropping'
export const FLIPPER_INCREASED_RELATED_UPCS_FOR_MEASUREMENT =
  'self_service_ui_increased_related_upcs_for_measurement'
export const FLIPPER_INFRA_DEBUG_BUTTON = 'self_service_ui_infra_debug_button'
export const FLIPPER_INSIGHTS_ADVANCED = 'self_service_ui_insights_advanced'
export const FLIPPER_INSIGHTS_DATA_VIZ = 'self_service_ui_insights_data_viz'
export const FLIPPER_INSIGHTS_EDIT_BUDGET_MODAL = 'self_service_ui_insights_edit_budget_modal'
export const FLIPPER_INSIGHTS_HIDE_MARKET_SHARE_WHEN_ONLY_DEPARTMENT =
  'self_service_ui_hide_market_share_when_only_department'
export const FLIPPER_INSIGHTS_PREFIX_CHANGE = 'self_service_ui_insights_prefix_change'
export const FLIPPER_INTERNAL_EDIT = 'self_service_ui_internal_edit_flipper'
export const FLIPPER_KEYWORD_TABLE_SUGGESTED_BID_SORT =
  'self_service_ui_keyword_table_suggested_bid_sort'
export const FLIPPER_KW_REPORT_LIMIT_FOR_OPTIMIZED_BIDDING =
  'self_service_ui_kw_report_limit_for_optimized_bidding'
export const FLIPPER_KW_REPORT_LIMIT_FOR_OPTIMIZED_BIDDING_DISABLED =
  'self_service_ui_kw_report_limit_for_optimized_bidding_disabled'
export const FLIPPER_LIFT_SUMMARY_REPORTING_KILL_SWITCH_CHART =
  'self_service_ui_lift_summary_reporting_kill_switch_chart'
export const FLIPPER_LIFT_SUMMARY_REPORTING_KILL_SWITCH_DATE_RANGE =
  'self_service_ui_lift_summary_reporting_kill_switch_date_range'
export const FLIPPER_LIFT_SUMMARY_REPORTING_KILL_SWITCH_EXPORT =
  'self_service_ui_lift_summary_reporting_kill_switch_sum_export'
export const FLIPPER_LIFT_SUMMARY_REPORTING_KILL_SWITCH_SUM_ROW =
  'self_service_ui_lift_summary_reporting_kill_switch_sum_row'
export const FLIPPER_LIFT_TESTS_KILL_SWITCH_CHART = 'self_service_ui_lift_tests_kill_switch_chart'
export const FLIPPER_LIFT_TESTS_KILL_SWITCH_FILTERS =
  'self_service_ui_lift_tests_kill_switch_filters'
export const FLIPPER_LIFT_TESTS_KILL_SWITCH_SUM_ROW =
  'self_service_ui_lift_tests_kill_switch_sum_row'
export const FLIPPER_LIFT_VARIANT_REPORTING_KILL_SWITCH_CHART =
  'self_service_ui_lift_variant_reporting_kill_switch_chart'
export const FLIPPER_LIFT_VARIANT_REPORTING_KILL_SWITCH_DATE_RANGE =
  'self_service_ui_lift_variant_reporting_kill_switch_date_range'
export const FLIPPER_LIFT_VARIANT_REPORTING_KILL_SWITCH_EXPORT =
  'self_service_ui_lift_variant_reporting_kill_switch_sum_export'
export const FLIPPER_LIFT_VARIANT_REPORTING_KILL_SWITCH_SUM_ROW =
  'self_service_ui_lift_variant_reporting_kill_switch_sum_row'
export const FLIPPER_LINEAR_ATTRIBUTION = 'self_service_ui_linear_attribution'
export const FLIPPER_LOST_SALES_HIDE_RECOMMENDATION =
  'self_service_ui_lost_sales_hide_recommendation'
export const FLIPPER_LYCHEE_EMAIL_FLOW = 'self_service_ui_lychee_email_flow'
export const FLIPPER_MEMOIZED_REPORTING_TABLE = 'self_service_ui_memoized_reporting_table'
export const FLIPPER_MRC_NO_DATA_CHECKBOX = 'self_service_ui_mrc_no_data_checkbox'
export const FLIPPER_MTA_PRERELEASE = 'self_service_ui_mta_prerelease'
export const FLIPPER_MULTI_FACTOR_AUTH = 'self_service_ui_multi_factor_auth'
export const FLIPPER_MULTI_FACTOR_AUTH_ADOPTION_MODAL =
  'self_service_ui_multi_factor_auth_adoption_modal'
export const FLIPPER_NEW_BILL_PAYER_UI = 'ads_core_ui_bill_payer_update'
export const FLIPPER_NEW_CHART = 'self_service_ui_new_chart'
export const FLIPPER_NEW_DEFAULT_DATE_RANGE = 'self_service_ui_default_date_range_2023_02'
export const FLIPPER_NEW_OB_GOAL_PICKER = 'self_service_ui_new_ob_goal_picker'
export const FLIPPER_NEW_TEST_FLIPPER = 'new_flipper'
export const FLIPPER_NEW_TEST_FLIPPER_DISABLE = 'new_flipper_disable'
export const FLIPPER_NEW_TO_BRAND_FLIPPER = 'self_service_ui_new_to_brand'
export const FLIPPER_NTB_ATTRIBUTED_QUNAITITES =
  'self_service_ui_new_to_brand_attributed_quantities'
export const FLIPPER_PAGE_LAYOUT_REFRESH = 'self_service_ui_page_layout_refresh'
export const FLIPPER_PA_REPORTING_GA_FEATURES = 'self_service_ui_pa_reporting_ga_features'
export const FLIPPER_PLU_PRODUCT_LIBRARY = 'ads_core_plu_product_library'
export const FLIPPER_PRODUCTS_TABLE_SHOW_MISSING_IMAGE_HINT =
  'self_service_ui_products_table_show_missing_image_hint'
export const FLIPPER_PRODUCT_SEARCH_RETAILER_PRIVATE_LABEL =
  'self_service_ui_product_search_retailer_private_label_search'
export const FLIPPER_PRODUCT_TYPE_FILTER = 'self_service_ui_product_type_filter'
export const FLIPPER_PROMOTIONS_CLEARING_HOUSE_OPTION =
  'self_service_ui_promotions_clearing_house_option'
export const FLIPPER_PROMOTIONS_DE_DUPLICATE_UPCS = 'self_service_ui_promotions_de_duplicate_upcs'
export const FLIPPER_PROMOTIONS_FREE_GIFT = 'self_service_ui_promotions_free_gift'
export const FLIPPER_PROMOTIONS_TARGETING = 'self_service_ui_promotions_targeting'
export const FLIPPER_PROVI_BRAND_PAGES_KILL_SWITCH_CHART =
  'self_service_ui_provi_brand_pages_kill_switch_chart'
export const FLIPPER_PROVI_BRAND_PAGES_KILL_SWITCH_FILTERS =
  'self_service_ui_provi_brand_pages_kill_switch_filters'
export const FLIPPER_PROVI_BRAND_PAGES_KILL_SWITCH_SUM_ROW =
  'self_service_ui_provi_brand_pages_kill_switch_sum_row'
export const FLIPPER_REDUCED_KEYWORD_INSIGHTS = 'self_service_ui_reduced_keyword_insights'
export const FLIPPER_REPORTING_CENTER_SURVEY_FORCE_TRIGGER =
  'self_service_ui_reporting_center_survey_force_trigger'
export const FLIPPER_REPORTING_CENTER_SURVEY_INSTACART_ACCOUNT =
  'self_service_ui_reporting_center_survey_instacart_account'
export const FLIPPER_RESERVATION_ATTRIBUTION_HOVERCARD =
  'self_service_ui_reservation_attribution_hovercard'
export const FLIPPER_RESERVATION_CAMPAIGNS_KILL_SWITCH_CHART =
  'self_service_ui_reservation_campaigns_kill_switch_chart'
export const FLIPPER_RESERVATION_CAMPAIGNS_KILL_SWITCH_DATE_RANGE =
  'self_service_ui_reservation_campaigns_kill_switch_date_range'
export const FLIPPER_RESERVATION_CAMPAIGNS_KILL_SWITCH_EXPORT =
  'self_service_ui_reservation_campaigns_kill_switch_sum_export'
export const FLIPPER_RESERVATION_CAMPAIGNS_KILL_SWITCH_SUM_ROW =
  'self_service_ui_reservation_campaigns_kill_switch_sum_row'
export const FLIPPER_RESERVATION_CAMPAIGN_REPORTING_KILL_SWITCH_CHART =
  'self_service_ui_reservation_campaign_reporting_kill_switch_chart'
export const FLIPPER_RESERVATION_CAMPAIGN_REPORTING_KILL_SWITCH_DATE_RANGE =
  'self_service_ui_reservation_campaign_reporting_kill_switch_date_range'
export const FLIPPER_RESERVATION_CAMPAIGN_REPORTING_KILL_SWITCH_EXPORT =
  'self_service_ui_reservation_campaign_reporting_kill_switch_sum_export'
export const FLIPPER_RESERVATION_CAMPAIGN_REPORTING_KILL_SWITCH_SUM_ROW =
  'self_service_ui_reservation_campaign_reporting_kill_switch_sum_row'
export const FLIPPER_RESERVATION_EXPORTS = 'self_service_ui_reservation_exports'
export const FLIPPER_RESERVATION_LANDING_KILL_SWITCH_EXPORT =
  'self_service_ui_reservation_landing_kill_switch_sum_export'
export const FLIPPER_RESERVATION_LANDING_KILL_SWITCH_SUM_ROW =
  'self_service_ui_reservation_landing_kill_switch_sum_row'
export const FLIPPER_RESERVATION_LANDING_PAGE = 'self_service_ui_reservation_landing_page'
export const FLIPPER_RESERVATION_OVERVIEW_KILL_SWITCH_CHART =
  'self_service_ui_reservation_overview_kill_switch_chart'
export const FLIPPER_RESERVATION_OVERVIEW_KILL_SWITCH_DATE_RANGE =
  'self_service_ui_reservation_overview_kill_switch_date_range'
export const FLIPPER_RESERVATION_OVERVIEW_KILL_SWITCH_EXPORT =
  'self_service_ui_reservation_overview_kill_switch_sum_export'
export const FLIPPER_RESERVATION_OVERVIEW_KILL_SWITCH_SUM_ROW =
  'self_service_ui_reservation_overview_kill_switch_sum_row'
export const FLIPPER_RESERVATION_PAUSE_TOGGLE = 'self_service_ui_reservation_pause_toggle'
export const FLIPPER_RESERVATION_REPORTING_KILL_SWITCH_CHART =
  'self_service_ui_reservation_reporting_kill_switch_chart'
export const FLIPPER_RESERVATION_REPORTING_KILL_SWITCH_DATE_RANGE =
  'self_service_ui_reservation_reporting_kill_switch_date_range'
export const FLIPPER_RESERVATION_REPORTING_KILL_SWITCH_EXPORT =
  'self_service_ui_reservation_reporting_kill_switch_sum_export'
export const FLIPPER_RESERVATION_REPORTING_KILL_SWITCH_SUM_ROW =
  'self_service_ui_reservation_reporting_kill_switch_sum_row'
export const FLIPPER_SALES_REPORT = 'self_service_ui_sales_report'
export const FLIPPER_SALES_SHOW_GRAPH = 'self_service_ui_sales_show_graph'
export const FLIPPER_SDS = 'self_service_ui_sods'
export const FLIPPER_SELECTIVE_METRICS_OBSCURING = 'self_service_ui_selective_metrics_obscuring'
export const FLIPPER_SHADOW_OPTIMIZED_TABLE_BODY = 'self_service_optimized_table_body'
export const FLIPPER_SHOPPABLE_GRID_ORDER = 'self_service_ui_shoppable_grid_order'
export const FLIPPER_SHOPPABLE_RECIPIES = 'ads_core_shoppable_recipes'
export const FLIPPER_SHOPPABLE_SINGLE_UPC = 'self_service_ui_shoppable_single_upc'
export const FLIPPER_SHOW_OAUTH_SCOPES = 'self_service_ui_show_oauth_scopes'
export const FLIPPER_SKB_KEYWORD_TAB = 'self_service_ui_skb_keyword_tab'
export const FLIPPER_SPONSORED_PRODUCT_ON_FRAP_REPORTING = 'self_service_ui_sp_on_frap_reporting'
export const FLIPPER_SP_AGO_KILL_SWITCH_TABLE = 'self_service_ui_sp_ago_kill_switch_table'
export const FLIPPER_TASK_PLATFORM_OUTPUT_M2_UI = 'self_service_ui_taskplatform_output_m2_ui'
export const FLIPPER_TEST = 'test_flipper'
export const FLIPPER_TEST_FLIPPER_ONE = 'self_service_ui_test_flipper_one'
export const FLIPPER_TEST_FLIPPER_TWO = 'self_service_ui_test_flipper_two'
export const FLIPPER_TIER_AUTOMATIC_REPORTING = 'self_service_ui_tier_automatic_reporting'
export const FLIPPER_TIER_MANAGEMENT_BLOCK_PORTAL_ACCESS =
  'self_service_ui_tier_management_block_portal_access'
export const FLIPPER_TIER_MANAGEMENT_EDIT_ASSIGNED_ACCOUNT_TIER =
  'self_service_ui_tier_management_edit_assigned_account_tier'
export const FLIPPER_TIER_MANAGEMENT_GROSS_AD_REVENUE =
  'self_service_ui_tier_management_gross_ad_revenue'
export const FLIPPER_UI_MRC_REPORTING = 'self_service_ui_mrc_reporting'
export const FLIPPER_UI_PRODUCT_LIBRARY_M1_ADMIN = 'self_service_ui_product_library_m1_admin'
export const FLIPPER_UI_PRODUCT_LIBRARY_PENDING_TAB = 'self_service_ui_product_library_pending_tab'
export const FLIPPER_UNATA_DATA_BANNER = 'self_service_ui_unata_data_banner'
export const FLIPPER_UNATA_DATA_BANNER_FORCE_ON = 'self_service_ui_unata_data_banner_force_on'
export const FLIPPER_UNIFIED_COMPONENTS = 'self_service_ui_unified_components'
export const FLIPPER_UX_IMPROVEMENTS_METRIC_DROP_DOWN =
  'self_service_ui_ux_improvements_metric_drop_down'
export const FLIPPER_UX_IMPROVEMENTS_SUM_ROW = 'self_service_ui_ux_improvements_sum_row'
export const FLIPPER_VIDEO_ACCOUNT_LEVEL_AD_GROUP = 'self_service_ui_video_account_level_ad_group'
export const FLIPPER_VIDEO_ACCOUNT_LEVEL_KEYWORD = 'self_service_ui_video_account_level_keyword'
export const FLIPPER_VIDEO_ACCOUNT_LEVEL_LANDING = 'self_service_ui_video_account_level_landing'
export const FLIPPER_VIDEO_AD_GROUPS_KILL_SWITCH_CHART =
  'self_service_ui_video_ad_groups_kill_switch_chart'
export const FLIPPER_VIDEO_AD_GROUPS_KILL_SWITCH_EXPORT =
  'self_service_ui_video_ad_groups_kill_switch_export'
export const FLIPPER_VIDEO_AD_GROUPS_KILL_SWITCH_FILTERS =
  'self_service_ui_video_ad_groups_kill_switch_filters'
export const FLIPPER_VIDEO_AD_GROUPS_KILL_SWITCH_SUM_ROW =
  'self_service_ui_video_ad_groups_kill_switch_sum_row'
export const FLIPPER_VIDEO_AD_GROUP_OVERVIEW_KILL_SWITCH_EXPORT =
  'self_service_ui_video_ad_group_overview_kill_switch_export'
export const FLIPPER_VIDEO_BRAND_PAGE_KILL_SWITCH_EXPORT =
  'self_service_ui_video_brand_page_kill_switch_export'
export const FLIPPER_VIDEO_CAMPAIGNS_KILL_SWITCH_CHART =
  'self_service_ui_video_campaigns_kill_switch_chart'
export const FLIPPER_VIDEO_CAMPAIGNS_KILL_SWITCH_EXPORT =
  'self_service_ui_video_campaigns_kill_switch_export'
export const FLIPPER_VIDEO_CAMPAIGNS_KILL_SWITCH_FILTERS =
  'self_service_ui_video_campaigns_kill_switch_filters'
export const FLIPPER_VIDEO_CAMPAIGNS_KILL_SWITCH_SUM_ROW =
  'self_service_ui_video_campaigns_kill_switch_sum_row'
export const FLIPPER_VIDEO_KEYWORDS_KILL_SWITCH_CHART =
  'self_service_ui_video_keywords_kill_switch_chart'
export const FLIPPER_VIDEO_KEYWORDS_KILL_SWITCH_EXPORT =
  'self_service_ui_video_keywords_kill_switch_export'
export const FLIPPER_VIDEO_KEYWORDS_KILL_SWITCH_FILTERS =
  'self_service_ui_video_keywords_kill_switch_filters'
export const FLIPPER_VIDEO_KEYWORDS_KILL_SWITCH_SUM_ROW =
  'self_service_ui_video_keywords_kill_switch_sum_row'
export const FLIPPER_VIDEO_LAST_TOUCH_METRICS = 'self_service_ui_video_last_touch_metrics'
export const FLIPPER_VIDEO_PAUSE_TOGGLE = 'self_service_ui_video_pause_toggle'
export const FLIPPER_VIDEO_PRODUCTS_KILL_SWITCH_CHART =
  'self_service_ui_video_products_kill_switch_chart'
export const FLIPPER_VIDEO_PRODUCTS_KILL_SWITCH_EXPORT =
  'self_service_ui_video_products_kill_switch_export'
export const FLIPPER_VIDEO_PRODUCTS_KILL_SWITCH_FILTERS =
  'self_service_ui_video_products_kill_switch_filters'
export const FLIPPER_VIDEO_PRODUCTS_KILL_SWITCH_SUM_ROW =
  'self_service_ui_video_products_kill_switch_sum_row'
export const FLIPPER_VIDEO_REPORT_A_PROBLEM = 'self_service_ui_video_report_a_problem'
export const FLIPPER_VIDEO_SUBTITLE = 'self_service_ui_video_subtitle'
export const FLIPPER_VISIBILITY_GROUP_CANADA = 'self_service_ui_visibility_group_canada'
export const FLIPPER_VISIBILITY_GROUP_ONE = 'self_service_ui_visibility_group_one'
export const FLIPPER_WAITLIST_LEAD_SOURCE_ENABLED = 'self_service_ui_waitlist_lead_source_enabled'
