// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb'
import type { GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsVideo } from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsVideo'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsVideoFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsVideoFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsVideoToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsVideo'

/**
 *
 * @export
 * @interface GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets
 */
export interface GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets {
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  topImageWeb?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  imageWeb?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  imageMobile?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  topImageMobile?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  squareImageMobile?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  heroImage?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  brandLogo?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsVideo}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  video?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsVideo
  /**
   *
   * @type {GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail}
   * @memberof GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  thumbnail?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail
}

/**
 * Check if a given object implements the GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets interface.
 */
export function instanceOfGetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSON(
  json: any
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets {
  return GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    topImageWeb: !exists(json, 'top_image_web')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebFromJSON(
          json['top_image_web']
        ),
    imageWeb: !exists(json, 'image_web')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebFromJSON(
          json['image_web']
        ),
    imageMobile: !exists(json, 'image_mobile')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileFromJSON(
          json['image_mobile']
        ),
    topImageMobile: !exists(json, 'top_image_mobile')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileFromJSON(
          json['top_image_mobile']
        ),
    squareImageMobile: !exists(json, 'square_image_mobile')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileFromJSON(
          json['square_image_mobile']
        ),
    heroImage: !exists(json, 'hero_image')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageFromJSON(
          json['hero_image']
        ),
    brandLogo: !exists(json, 'brand_logo')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoFromJSON(
          json['brand_logo']
        ),
    video: !exists(json, 'video')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsVideoFromJSON(
          json['video']
        ),
    thumbnail: !exists(json, 'thumbnail')
      ? undefined
      : GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailFromJSON(
          json['thumbnail']
        ),
  }
}

export function GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsToJSON(
  value?: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    top_image_web:
      GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebToJSON(
        value.topImageWeb
      ),
    image_web: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebToJSON(
      value.imageWeb
    ),
    image_mobile:
      GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileToJSON(
        value.imageMobile
      ),
    top_image_mobile:
      GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileToJSON(
        value.topImageMobile
      ),
    square_image_mobile:
      GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileToJSON(
        value.squareImageMobile
      ),
    hero_image:
      GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageToJSON(
        value.heroImage
      ),
    brand_logo:
      GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoToJSON(
        value.brandLogo
      ),
    video: GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsVideoToJSON(
      value.video
    ),
    thumbnail:
      GetDisplayCampaignsParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailToJSON(
        value.thumbnail
      ),
  }
}
