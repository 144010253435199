import { css } from '@emotion/react'
import {
  ConfirmIcon,
  CutIcon,
  ReplaceIcon,
  spacing,
  Theme,
  TrashIcon,
  UploadIcon,
  useTheme,
} from '@instacart/ids-core'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import useIntl from 'common/useIntl'
import Loader from 'components/AssetManager/Loader'
import { SecondaryButton, TertiaryButton } from 'components/ids-ads'

export interface UploaderButtonProps {
  onUpload: () => void
  onRemove: () => void
  loading?: boolean
  fileUrl?: string
  imageCroppingEnabled?: boolean
  isCropping?: boolean
  onShowCrop?: () => void
  onSaveCrop?: () => void
  onCancelCrop?: () => void
  cropErrors?: string[]
}

const FOCUS_MS = 200

const getFocusStyles = (theme: Theme) => ({
  '&:focus-visible': {
    transition: `all ${FOCUS_MS}ms ease-in-out`,
    boxShadow: `0 0 0 2px ${theme.colors.systemGrayscale70} inset`,
  },
  '&.loading': {
    cursor: 'no-drop',
  },
})

const useStyles = (theme: Theme) => {
  return {
    container: css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      gap: spacing.s12,
      margin: `${spacing.s12}px 0`,
      padding: '0 8px',
    }),
    removeButton: css({
      '& > span > svg': {
        marginRight: 0,
      },
    }),
    focus: css(getFocusStyles(theme)),
  }
}

const UploaderButton = ({
  onUpload,
  onRemove,
  loading,
  fileUrl,
  imageCroppingEnabled = false,
  isCropping = false,
  onShowCrop,
  onSaveCrop,
  onCancelCrop,
  cropErrors,
}: UploaderButtonProps) => {
  const intl = useIntl()
  const theme = useTheme()
  const styles = useStyles(theme)
  // TODO: The ability to re-crop an image has been deferred.
  // Once all issues with re-cropping have been resolved and it is
  // ready for release, this flag can be removed along with
  // the single instance it is used below to conditionally render
  // the "Crop" (i.e. re-crop) button.
  const isRecroppingEnabled = false

  const UploadButton = () => (
    <SecondaryButton
      type="button"
      onClick={onUpload}
      className={classnames({ loading })}
      disabled={loading}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <UploadIcon color="systemGrayscale70" size={24} />
          <FormattedMessage id="common.upload" />
        </>
      )}
    </SecondaryButton>
  )

  const ReplaceButton = () => (
    <TertiaryButton
      type="button"
      className={classnames({ loading })}
      onClick={onUpload}
      disabled={loading}
      icon={ReplaceIcon}
    >
      {loading ? <Loader /> : <FormattedMessage id="common.replace" />}
    </TertiaryButton>
  )

  const RemoveButton = () => (
    <TertiaryButton
      type="button"
      title={intl.formatMessage({ id: 'common.remove' })}
      css={styles.removeButton}
      onClick={onRemove}
      disabled={loading}
      icon={TrashIcon}
    />
  )

  const ReplaceButtons = () => {
    if (!imageCroppingEnabled) {
      return (
        <>
          <ReplaceButton />
          <RemoveButton />
        </>
      )
    }

    if (!isCropping) {
      return (
        <>
          <ReplaceButton />
          {isRecroppingEnabled && (
            <TertiaryButton
              type="button"
              title={intl.formatMessage({ id: 'common.crop' })}
              onClick={onShowCrop}
              disabled={loading || isCropping}
              icon={CutIcon}
            >
              <FormattedMessage id="common.crop" />
            </TertiaryButton>
          )}
          <RemoveButton />
        </>
      )
    }

    return (
      <>
        <TertiaryButton
          type="button"
          title={intl.formatMessage({ id: 'common.cancel' })}
          onClick={onCancelCrop}
          disabled={loading}
        >
          <FormattedMessage id="common.cancel" />
        </TertiaryButton>
        <SecondaryButton
          data-testId="save-crop-button"
          type="button"
          title={intl.formatMessage({ id: 'common.saveCrop' })}
          onClick={onSaveCrop}
          disabled={loading || !!cropErrors}
          icon={ConfirmIcon}
        >
          <FormattedMessage id="common.saveCrop" />
        </SecondaryButton>
      </>
    )
  }

  const buttons = fileUrl ? <ReplaceButtons /> : <UploadButton />

  return <div css={styles.container}>{buttons}</div>
}

export default UploaderButton
