/* eslint-disable max-lines */
import { FormikConfig, FormikErrors } from 'formik'
import { History } from 'history'
import {
  ReactNode,
  useContext,
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { boldSpanFormattingHelper, useIntl } from 'common'
import { getCreativeSchema, CreativeSchema } from 'common/common-definitions/creative'
import { handleApiError } from 'common/handleApiError'
import { NotificationType, Notifier, useNotifier, useAuthContext } from 'context'
import { AdGroupWizardData } from 'pages/DisplayProduct/common/utils/utils'
import {
  DisplayAdGroupBackendData,
  DisplayAdGroupFormData,
  PlacementType,
} from 'pages/DisplayProduct/DisplayAdGroup/displayAdGroup.types'
import {
  toCreateDisplayAdGroupRequest,
  toPutDisplayAdGroupsIdRequest,
} from 'pages/DisplayProduct/DisplayAdGroup/hooks/adGroup.api'
import {
  RoutePaths,
  useCampaignId,
  useRoutePaths,
} from 'pages/DisplayProduct/displayAndEmail.routes'
import {
  toCreateDisplayCampaignRequest,
  toUpdateDisplayCampaignsRequest,
} from 'pages/DisplayProduct/DisplayCampaign/displayCampaign.hooks'
import { DisplayCampaignFormValues } from 'pages/DisplayProduct/DisplayCampaign/displayCampaign.types'
import { ReservationAdGroupWizardData } from 'pages/ReservationCampaigns/types'
import { DisplayAdGroupsApi } from 'service/apis/DisplayAdGroupsApi'
import { DisplayCampaignsApi } from 'service/apis/DisplayCampaignsApi'
import { RecipesApi } from 'service/apis/RecipesApi'
import { PutDisplayCampaignsIdRequest } from 'service/openapi/__codegen__/apis/DisplayCampaignsApi'
import { AdminAdGroupResponse } from 'service/openapi/__codegen__/models/AdminAdGroupResponse'
import { AdminDisplayAdGroupResponseDataAttributesCreativeTypeEnum as CreativeTypeEnum } from 'service/openapi/__codegen__/models/AdminDisplayAdGroupResponseDataAttributesCreative'
import { DisplayAdGroupResponse } from 'service/openapi/__codegen__/models/DisplayAdGroupResponse'
import { DisplayAdGroupResponseData } from 'service/openapi/__codegen__/models/DisplayAdGroupResponseData'
import { DisplayAdGroupsResponse } from 'service/openapi/__codegen__/models/DisplayAdGroupsResponse'
import { DisplayCampaignCreateResponse } from 'service/openapi/__codegen__/models/DisplayCampaignCreateResponse'
import { DisplayCampaignResponse } from 'service/openapi/__codegen__/models/DisplayCampaignResponse'
import { DisplayCampaignResponseData } from 'service/openapi/__codegen__/models/DisplayCampaignResponseData'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsTypeEnum,
  PutDisplayCampaignsIdParamDataAttributesAdGroups as DisplayAdGroup,
} from 'service/openapi/__codegen__/models/PutDisplayCampaignsIdParamDataAttributesAdGroups'
import {
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributes,
  PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesStatusEnum,
} from 'service/openapi/__codegen__/models/PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributes'
import { RecipeProductsResponse } from 'service/openapi/__codegen__/models/RecipeProductsResponse'
import { RecipeProductsResponseData } from 'service/openapi/__codegen__/models/RecipeProductsResponseData'
import { StandaloneBrandPageResponseData } from 'service/openapi/__codegen__/models/StandaloneBrandPageResponseData'
import { CampaignTypeEnum } from 'types/campaigns'
import { trackCampaignSaveEvent } from './common/utils/analytics'
import { getCreativeTypeFromGoalFormat } from './DisplayAdGroup/components/DisplayAdGroupForm/displayAdGroupForm.utils'

type CampaignFormikOnSubmit = FormikConfig<DisplayCampaignFormValues>['onSubmit']
export type DisplayFormDataValues = DisplayCampaignFormValues | DisplayAdGroupFormData

interface SaveAdGroupParams {
  formValues: DisplayAdGroupFormData
  setErrors: (errors: FormikErrors<DisplayAdGroupFormData>) => void
  isDirty: boolean
  adGroupId?: string
  adGroupIndex?: string
  skipNonEmptyValidation?: boolean
  hasCampaignLandingPage: boolean
}

export type TriggerSaveDraftAdGroup = 'save' | 'loading' | 'success' | 'fail'

export type DisplayContextState = {
  campaignId: string | undefined
  campaignType: CampaignTypeEnum
  campaign: DisplayCampaignResponseData | undefined
  routePaths: RoutePaths
  loading: boolean
  campaignLoading: boolean
  exitAfterSave: boolean
  currentAdGroupFormat: string | undefined
  currentAdGroupName: string
  saveCampaign: CampaignFormikOnSubmit
  // gets a persisted ad group by its id
  getAdGroupById: (adGroupId: string) => DisplayAdGroupBackendData | undefined
  // gets an unsaved ad group by its index
  getAdGroupByIndex: (adGroupIndex: string) => AdGroupWizardData | undefined
  getAdGroups: () => AdGroupWizardData[]
  getPersistedAdGroups: () => AdGroupWizardData[]
  getUnsavedAdGroups: () => AdGroupWizardData[]
  // adds a new unsaved ad group to let user fill the form
  addAdGroup: () => void
  saveAdGroup: (
    params: SaveAdGroupParams
  ) => Promise<Partial<DisplayAdGroupResponseData> | undefined>
  saveAdGroupAndNavigate: (
    params: SaveAdGroupParams
  ) => Promise<Partial<DisplayAdGroupResponseData> | undefined>
  submitAdGroups: (ids: string[]) => Promise<void>
  setExitAfterSave: (exit: boolean) => void
  reviewAfterSave: boolean
  setReviewAfterSave: (review: boolean) => void
  setCurrentAdGroupName: (name: string) => void
  setCurrentAdGroupFormat: (format: string | undefined) => void
  setLoading: (loading: boolean) => void
  isAdGroupLast: (adGroup: AdGroupWizardData) => boolean
  expandBrandPageSections: boolean
  setExpandBrandPageSections: Dispatch<SetStateAction<boolean>>
  triggerSaveDraftAdGroupStatus?: TriggerSaveDraftAdGroup
  setTriggerSaveDraftAdGroupStatus: (
    triggerSaveDraftAdGroupStatus?: TriggerSaveDraftAdGroup
  ) => void
  currentBrandPage: StandaloneBrandPageResponseData | undefined
  setCurrentBrandPage: (currentBrandPage: StandaloneBrandPageResponseData | undefined) => void
  updateCampaign: (values: PutDisplayCampaignsIdRequest) => void
  creativeSchema: CreativeSchema
  getRecipeProducts: (recipeId: string) => Promise<RecipeProductsResponseData | undefined>
}

type SaveResponse =
  | {
      adGroupResponseData: DisplayAdGroupResponseData | { id: string }
      newCampaign: DisplayCampaignResponseData
      newUnsavedAdGroups: AdGroupWizardData[]
      saved: true
    }
  | {
      adGroupResponseData: { id?: string }
      newCampaign: DisplayCampaignResponseData
      newUnsavedAdGroups: AdGroupWizardData[]
      saved: false
    }

export const DisplayContext = createContext<DisplayContextState | undefined>(undefined)

export const useDisplayContext = () => {
  const contextState = useContext(DisplayContext)
  if (!contextState) {
    throw new Error('useDisplayContext should be used in DisplayContextProvider component')
  }
  return contextState
}

export interface DisplayContextProviderProps {
  children: ReactNode
}

export const goToDashboard = (history: History, routePaths: RoutePaths) => {
  history.push(`${routePaths.BASE_PATH}/dashboard`)
}

export const goToCampaignDetails = (
  history: History,
  campaignId: string,
  routePaths: RoutePaths
) => {
  history.push(`${routePaths.BASE_PATH}/campaign/${campaignId}`)
}

export const goToCampaignReview = (
  history: History,
  campaignId: string,
  routePaths: RoutePaths
) => {
  history.push(`${routePaths.BASE_PATH}/campaign/${campaignId}/review`)
}

export interface GoToAdGroupEditParams {
  history: History
  campaignId: string
  adGroupId: string
  routePaths: RoutePaths
}

export const goToAdGroupEdit = ({
  history,
  campaignId,
  adGroupId,
  routePaths,
}: GoToAdGroupEditParams) => {
  history.replace(`${routePaths.BASE_PATH}/campaign/${campaignId}/ad_group/${adGroupId}/edit`)
}

export const goToNext = ({
  history,
  campaignId,
  adGroups,
  adGroupId,
  adGroupIndex,
  routePaths,
}: {
  history: History
  campaignId: string
  adGroups: AdGroupWizardData[] | ReservationAdGroupWizardData[]
  adGroupId?: string
  adGroupIndex?: string
  routePaths: RoutePaths
}) => {
  // There are AdGroups coming in the campaign response as well as the ones created with "plus" icon and not saved yet
  // Ad groups will have `id` if already persisted, or `index` if not persisted yet.
  // eg: [id1, id2, id3, index0, index1], first 3 are persisted ad groups coming from backend, last 2 are just created
  // on the UI and not saved yet.
  const currentIndex = adGroups.findIndex(ag => {
    // adGroupId has priority over adGroupIndex as just after creating a new ad group, entry with `index` will be replaced
    // with backend response that has `id` in adGrops array
    if (adGroupId) return ag.id === adGroupId
    if (adGroupIndex) return ag.index === adGroupId
    return false
  })
  // if adGroupId and adGroupIndex is not passed, currentIndex will be -1. This is the case while navigating from campaign
  // to ad group. Since nextIndex will be 0, it will navigate to the first ad group. So, no special handling needed.
  const nextIndex = currentIndex + 1

  if (nextIndex < adGroups.length) {
    const nextAdGroup = adGroups[nextIndex]
    if (nextAdGroup.id) {
      history.push(
        generatePath(routePaths.AD_GROUP_EDIT, {
          campaignId,
          adGroupId: nextAdGroup.id,
        })
      )
    } else {
      history.push(
        generatePath(routePaths.AD_GROUP_CREATE, {
          campaignId,
          index: nextAdGroup.index,
        })
      )
    }
  } else if (!adGroups.length) {
    history.push(
      generatePath(routePaths.AD_GROUP_CREATE, {
        campaignId,
        index: 0,
      })
    )
  } else {
    history.push(
      generatePath(routePaths.CAMPAIGN_REVIEW, {
        campaignId,
      })
    )
  }
}

export const sendSaveNotification = (
  notifier: Notifier,
  notificationType: NotificationType,
  name: string
) => {
  const messageId =
    notificationType === NotificationType.SUCCESS
      ? 'pages.displayProduct.notification.save.success'
      : 'pages.displayProduct.notification.save.error'
  notifier.sendNotification({
    messageId,
    messageValues: { name, ...boldSpanFormattingHelper },
    type: notificationType,
  })
}

export const sendSubmitNotification = (
  notifier: Notifier,
  notificationType: NotificationType,
  name: string
) => {
  const messageId =
    notificationType === NotificationType.SUCCESS
      ? 'pages.displayProduct.notification.submit.success'
      : 'pages.displayProduct.notification.submit.error'
  notifier.sendNotification({
    messageId,
    messageValues: { name, ...boldSpanFormattingHelper },
    type: notificationType,
  })
}

const addAdGroupToCampaign = (
  campaign: DisplayCampaignResponseData | undefined,
  adGroup: DisplayAdGroupResponseData
): DisplayCampaignResponseData => {
  if (!campaign) throw Error('Campaign does not exist')
  const existingAdGroups = (campaign.attributes.adGroups as DisplayAdGroupBackendData[]) || []
  const existingAdGroup = existingAdGroups.find(ag => ag.id === adGroup.id)
  const adGroups = existingAdGroup
    ? existingAdGroups.map(ag => (ag.id !== adGroup.id ? ag : adGroup))
    : [...existingAdGroups, adGroup as unknown as DisplayAdGroup]

  return {
    ...campaign,
    attributes: {
      ...campaign.attributes,
      adGroups: adGroups as DisplayAdGroup[],
    },
  }
}

const addCampaignInfoFromResponse = (
  prevCampaign: DisplayCampaignResponseData | undefined,
  campaignResponseData: DisplayCampaignResponseData | undefined
) => {
  if (!campaignResponseData) throw Error('Campaign does not exist')
  return {
    ...campaignResponseData,
    attributes: {
      ...campaignResponseData.attributes,
      // keep ad groups as they're returned back only from Campaign.show, but not Campaign.create and Campaign.update
      adGroups: prevCampaign?.attributes.adGroups || [],
    },
  }
}

function DisplayContextProvider({ children }: DisplayContextProviderProps) {
  const history = useHistory()
  const notifier = useNotifier()
  const [campaign, setCampaign] = useState<DisplayCampaignResponseData | undefined>(undefined)
  const [unsavedAdGroups, setUnsavedAdGroups] = useState<AdGroupWizardData[]>([])
  const [loading, setLoading] = useState(false)
  const [campaignLoading, setCampaignLoading] = useState(false)
  const [exit, setExit] = useState(false)
  const [currentAdGroupName, setCurrentAdGroupName] = useState('')
  const [currentAdGroupFormat, setCurrentAdGroupFormat] = useState<string | undefined>(undefined)
  const [review, setReview] = useState(false)
  const [expandBrandPageSections, setExpandBrandPageSections] = useState(false)
  const [triggerSaveDraftAdGroupStatus, setTriggerSaveDraftAdGroupStatus] =
    useState<TriggerSaveDraftAdGroup>()
  const [currentBrandPage, setCurrentBrandPage] = useState<
    StandaloneBrandPageResponseData | undefined
  >(undefined)

  const campaignId = useCampaignId()
  const routePaths = useRoutePaths()
  const intl = useIntl()
  const authContext = useAuthContext()

  const goalBasedCreativeType = getCreativeTypeFromGoalFormat(
    authContext,
    campaign?.attributes?.goalFormat
  )
  const creativeType = goalBasedCreativeType || CreativeTypeEnum.SearchBannerV1

  const [creativeSchema, setCreativeSchema] = useState(getCreativeSchema(creativeType))

  // Fetch the creative schema for the current creative type
  useEffect(() => {
    setCreativeSchema(getCreativeSchema(creativeType))
  }, [creativeType])

  function defaultDisplayAdGroup(index: number, id: string) {
    return {
      index: index.toString(),
      type: PutDisplayCampaignsIdParamDataAttributesAdGroupsTypeEnum.DisplayAdGroup,
      attributes: {
        name: `Untitled ad group ${index + 1}`,
        campaignId: id,
        status: PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributesStatusEnum.Draft,
        placementType: PlacementType.SEARCH_TOP,
        creative: {
          type: creativeType,
          assets: {},
        },
        targetingStrategy: {},
      },
    }
  }

  function defaultAdGroup({ index, id }: { index: number; id: string }) {
    return defaultDisplayAdGroup(index, id)
  }

  type ApiErrorHandlingOptions = {
    params: string[]
    setFormikErrors?: (errors: FormikErrors<DisplayFormDataValues>) => void
  }
  async function callApi<
    Response extends
      | DisplayCampaignResponse
      | DisplayAdGroupResponse
      | DisplayAdGroupsResponse
      | AdminAdGroupResponse
      | DisplayCampaignCreateResponse
      | RecipeProductsResponse
  >(
    fn: () => Promise<Response>,
    apiErrorHandlingOptions: ApiErrorHandlingOptions = { params: [], setFormikErrors: undefined },
    isNexusRequest = true
  ): Promise<Response['data'] | undefined> {
    setLoading(true)
    try {
      const apiResponse = await fn()
      return apiResponse.data
    } catch (err) {
      const { params } = apiErrorHandlingOptions
      const { setFormikErrors } = apiErrorHandlingOptions
      handleApiError<DisplayFormDataValues>({
        error: err,
        params,
        notifier,
        setFormikErrors,
        intl,
        isNexusRequest,
      })
    } finally {
      setLoading(false)
    }
    return undefined
  }

  // initialize the campaign
  useEffect(() => {
    if (campaignId && campaign?.id !== campaignId) {
      getCampaign()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId])

  async function getCampaign() {
    setCampaignLoading(true)
    if (campaignId) {
      const campaignResponseData = await callApi(() =>
        DisplayCampaignsApi.getDisplayCampaignsId({
          id: campaignId,
          includeDetails: 'true',
          includeAnalytics: true,
        })
      )
      setCampaign(campaignResponseData)
    }
    setCampaignLoading(false)
  }

  async function updateCampaign(values: PutDisplayCampaignsIdRequest) {
    if (!campaignId) return

    const response = await DisplayCampaignsApi.putDisplayCampaignsId(values)

    setCampaign({
      ...response.data,
      attributes: { ...response.data.attributes, adGroups: campaign?.attributes.adGroups },
    })
  }

  const saveCampaign: CampaignFormikOnSubmit = async (
    formValues: DisplayCampaignFormValues,
    { setErrors }
  ) => {
    // create or update the campaign
    let campaignResponseData: DisplayCampaignResponseData | undefined
    if (campaignId) {
      campaignResponseData = await callApi(
        () => {
          const request = toUpdateDisplayCampaignsRequest(formValues, { id: campaignId })
          return DisplayCampaignsApi.putDisplayCampaignsId(request)
        },
        { params: Object.keys(formValues), setFormikErrors: setErrors }
      )
    } else {
      const campaignCreateResponse = await callApi(
        () => {
          const request = toCreateDisplayCampaignRequest(formValues)
          return DisplayCampaignsApi.postDisplayCampaigns(request)
        },
        { params: Object.keys(formValues), setFormikErrors: setErrors }
      )
      if (campaignCreateResponse) {
        const response = await DisplayCampaignsApi.getDisplayCampaignsId({
          id: campaignCreateResponse.id,
        })
        campaignResponseData = response.data
      }
    }

    // Failed to save; an error should have already been shown to the user.
    if (!campaignResponseData) {
      return
    }

    trackCampaignSaveEvent(!!campaignId, campaignResponseData)

    // set the campaign in context
    const newCampaign = addCampaignInfoFromResponse(campaign, campaignResponseData)
    setCampaign(newCampaign)
    // navigate to the next step on success
    const adGroups = [
      ...(newCampaign.attributes.adGroups as AdGroupWizardData[]),
      ...unsavedAdGroups,
    ]

    if (exit) {
      goToCampaignDetails(history, newCampaign.id, routePaths)
    } else {
      if (!campaignId)
        setUnsavedAdGroups([
          defaultAdGroup({
            index: 0,
            id: newCampaign.id,
          }),
        ])
      goToNext({
        history,
        campaignId: campaignResponseData.id,
        adGroups,
        routePaths,
      })
    }
    sendSaveNotification(notifier, NotificationType.SUCCESS, formValues.name)
  }

  function getAdGroups() {
    return [...(getPersistedAdGroups() as AdGroupWizardData[]), ...unsavedAdGroups]
  }

  function getUnsavedAdGroups() {
    return unsavedAdGroups
  }

  function getPersistedAdGroups() {
    return (
      (campaign?.attributes.adGroups as DisplayAdGroupBackendData[])?.sort((a, b) => {
        const attrA = a.attributes as PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
        const attrB = b.attributes as PutDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
        const dateA = new Date(attrA?.createdAt || '')
        const dateB = new Date(attrB?.createdAt || '')
        return dateA.getTime() - dateB.getTime()
      }) || []
    )
  }

  function getAdGroupById(adGroupId: string) {
    return getPersistedAdGroups().find(ag => ag.id === adGroupId)
  }

  function getAdGroupByIndex(adGroupIndex: string) {
    return unsavedAdGroups.find(ag => ag.index === adGroupIndex)
  }

  function isAdGroupLast(adGroup: AdGroupWizardData) {
    const adGroups = getAdGroups()
    return adGroups.indexOf(adGroup) === adGroups.length - 1
  }

  function addAdGroup() {
    if (!campaignId) {
      throw new Error('An existing campaign needed to add an ad group')
    }

    const adGroups = getAdGroups()
    const lastAdGroup = adGroups[adGroups.length - 1]
    const nextIndex = lastAdGroup?.index ? parseInt(lastAdGroup.index, 10) + 1 : 0
    setUnsavedAdGroups(prevUnsavedAdGroups => [
      ...prevUnsavedAdGroups,
      defaultAdGroup({ index: nextIndex, id: campaignId }),
    ])
  }

  function validateCampaignBudget(): boolean {
    if (!campaign?.attributes.budget) {
      notifier.sendNotification({
        messageId: 'pages.displayProduct.notification.submit.invalidCampaignBudget',
        type: NotificationType.ERROR,
      })
      return false
    }

    return true
  }

  async function save({
    formValues,
    setErrors,
    adGroupId,
    adGroupIndex,
    skipNonEmptyValidation,
    isDirty,
    hasCampaignLandingPage,
  }: SaveAdGroupParams): Promise<SaveResponse | undefined> {
    if (!campaignId) {
      throw new Error('An existing campaign needed to create an ad group')
    }

    if (!isDirty) {
      if (!campaign) throw Error('Campaign does not exist')

      return {
        adGroupResponseData: { id: adGroupId },
        newCampaign: campaign,
        newUnsavedAdGroups: adGroupIndex
          ? unsavedAdGroups.filter(ag => ag.index !== adGroupIndex)
          : unsavedAdGroups,
        saved: false,
      }
    }

    if (!validateCampaignBudget()) return

    // create or update the ad group
    let adGroupResponseData: DisplayAdGroupResponseData | undefined
    if (adGroupId) {
      adGroupResponseData = await callApi(
        () => {
          const request = toPutDisplayAdGroupsIdRequest({
            authContext,
            formData: formValues,
            additionalParams: {
              id: adGroupId,
              campaignId,
              hasCampaignLandingPage,
            },
            skipNonEmptyValidation,
          })
          return DisplayAdGroupsApi.putDisplayAdGroupsId(request)
        },
        { params: Object.keys(formValues), setFormikErrors: setErrors }
      )
    } else {
      adGroupResponseData = await callApi(
        () => {
          const request = toCreateDisplayAdGroupRequest({
            authContext,
            formData: formValues,
            additionalParams: {
              campaignId,
              hasCampaignLandingPage,
            },
            skipNonEmptyValidation,
          })
          return DisplayAdGroupsApi.postDisplayAdGroups(request)
        },
        { params: Object.keys(formValues), setFormikErrors: setErrors }
      )
    }
    // Failed to save; an error should have already been shown to the user.
    if (!adGroupResponseData) {
      return
    }
    // calculate new campaign by adding the new ad group response
    const newCampaign = addAdGroupToCampaign(campaign, adGroupResponseData)
    // remove saved ad group from unsavedAdGroups if it's just created
    const newUnsavedAdGroups = adGroupIndex
      ? unsavedAdGroups.filter(ag => ag.index !== adGroupIndex)
      : unsavedAdGroups

    setCampaign(newCampaign)
    setUnsavedAdGroups(newUnsavedAdGroups)

    return { adGroupResponseData, newCampaign, newUnsavedAdGroups, saved: true }
  }

  async function saveAdGroup({
    formValues,
    setErrors,
    adGroupId,
    adGroupIndex,
    skipNonEmptyValidation,
    isDirty,
    hasCampaignLandingPage,
  }: SaveAdGroupParams) {
    if (!isDirty) {
      if (triggerSaveDraftAdGroupStatus === 'save') setTriggerSaveDraftAdGroupStatus('success')
      return
    }
    setTriggerSaveDraftAdGroupStatus('loading')

    const response = await save({
      formValues,
      setErrors,
      adGroupId,
      adGroupIndex,
      skipNonEmptyValidation,
      isDirty,
      hasCampaignLandingPage,
    })

    if (!response) return

    const { adGroupResponseData, newCampaign, saved } = response

    // Failed to save; an error should have already been shown to the user.
    if (!adGroupResponseData || !newCampaign) return
    if (triggerSaveDraftAdGroupStatus === 'save') setTriggerSaveDraftAdGroupStatus('success')

    setReview(true)
    // If it's a new ad group, update it to be the new edit link
    if (!adGroupId && saved) {
      goToAdGroupEdit({
        history,
        campaignId: newCampaign?.id,
        adGroupId: adGroupResponseData.id as string,
        routePaths,
      })
    }

    setReview(false)
    if (saved) sendSaveNotification(notifier, NotificationType.SUCCESS, formValues.name)

    return adGroupResponseData
  }

  async function saveAdGroupAndNavigate({
    formValues,
    setErrors,
    adGroupId,
    adGroupIndex,
    skipNonEmptyValidation,
    isDirty,
    hasCampaignLandingPage,
  }: SaveAdGroupParams) {
    const response = await save({
      formValues,
      setErrors,
      adGroupId,
      adGroupIndex,
      skipNonEmptyValidation,
      isDirty,
      hasCampaignLandingPage,
    })

    if (!response) return

    const { adGroupResponseData, newCampaign, newUnsavedAdGroups, saved } = response

    // Failed to save; an error should have already been shown to the user.
    if (!adGroupResponseData || !newCampaign) {
      return
    }

    // navigate to the next step on success
    const adGroups = [
      ...(newCampaign?.attributes.adGroups as AdGroupWizardData[]),
      ...newUnsavedAdGroups,
    ]

    setCurrentAdGroupName('')
    if (exit) goToCampaignDetails(history, newCampaign?.id, routePaths)
    else if (review) goToCampaignReview(history, newCampaign?.id, routePaths)
    else
      goToNext({
        history,
        campaignId: newCampaign?.id,
        adGroups,
        adGroupId: adGroupResponseData.id,
        adGroupIndex,
        routePaths,
      })

    if (saved) sendSaveNotification(notifier, NotificationType.SUCCESS, formValues.name)

    return adGroupResponseData
  }

  async function submitAdGroups(ids: string[]) {
    await callApi(() => DisplayAdGroupsApi.postDisplayAdGroupsSubmit({ body: { ids } }))
  }

  async function getRecipeProducts(recipeId: string) {
    const productIds = await callApi(
      () => RecipesApi.getRecipesIdProducts({ id: recipeId }),
      { params: [], setFormikErrors: undefined },
      false
    )
    return productIds
  }

  const value: DisplayContextState = {
    campaignId,
    campaignType: CampaignTypeEnum.Display,
    campaign,
    routePaths,
    loading,
    campaignLoading,
    exitAfterSave: exit,
    currentAdGroupFormat,
    currentAdGroupName,
    saveCampaign,
    getAdGroups,
    getPersistedAdGroups,
    getUnsavedAdGroups,
    getAdGroupById,
    getAdGroupByIndex,
    addAdGroup,
    saveAdGroup,
    saveAdGroupAndNavigate,
    submitAdGroups,
    setExitAfterSave: setExit,
    reviewAfterSave: review,
    setReviewAfterSave: setReview,
    setCurrentAdGroupFormat,
    setCurrentAdGroupName,
    setLoading,
    isAdGroupLast,
    expandBrandPageSections,
    setExpandBrandPageSections,
    triggerSaveDraftAdGroupStatus,
    setTriggerSaveDraftAdGroupStatus,
    currentBrandPage,
    setCurrentBrandPage,
    updateCampaign,
    creativeSchema,
    getRecipeProducts,
  }
  return <DisplayContext.Provider value={value}>{children}</DisplayContext.Provider>
}

export default DisplayContextProvider
