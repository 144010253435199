import { css, SerializedStyles } from '@emotion/react'
import {
  RadioSelectorBase,
  RadioSelectorBaseProps,
  spacing,
  SVGIconProps,
  useTheme,
} from '@instacart/ids-core'
import { ComponentType, ReactNode } from 'react'
import { HovercardTooltip } from 'components/ids-ads'
import { MessageIdType } from 'locales/types'
import FormattedMessage from '../../FormattedMessage'

export interface RadioCardProps extends Omit<RadioSelectorBaseProps, 'value'> {
  checked?: boolean
  disabled?: boolean
  label: string
  image?: ReactNode
  pillText?: string
  pillStyles?: SerializedStyles
  children?: ReactNode
  tooltipId?: MessageIdType
  maxWidth?: string // CSS string
  value: RadioSelectorBaseProps['value'] | boolean
  onClick: (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => void
  icon?: ComponentType<React.PropsWithChildren<Omit<SVGIconProps, 'component'>>>
}

const useStyles = ({ checked, disabled, maxWidth }: Partial<RadioCardProps>) => {
  const theme = useTheme()

  return {
    container: css({
      background: theme.colors.systemGrayscale00,
      borderRadius: theme.radius.r8,
      border: `1px solid ${theme.colors.systemGrayscale30}`,
      padding: '12px 16px 12px 30px',
      display: 'flex',
      maxWidth,
      position: 'relative',
      '&:focus-visible': {
        outline: `1px solid ${theme.colors.systemGrayscale30}`,
      },
      '&:hover': {
        cursor: 'pointer',
      },
      ...(checked && {
        borderColor: theme.colors.systemSuccessRegular,
      }),
      ...(disabled && {
        filter: 'opacity(40%)',
        '&:hover': {
          cursor: 'not-allowed',
        },
      }),
    }),
    imageSection: css({
      marginLeft: spacing.s12,
    }),
    radio: css({
      position: 'absolute',
      top: '13px',
      left: '9px',
      'div, input, svg': {
        width: '14px',
        height: '14px',
      },
    }),
    pill: css({
      marginLeft: '5px',
      borderRadius: '16px',
      background: theme.colors.systemSuccessLight,
      color: theme.colors.systemSuccessDark,
      padding: '3px 7px',
      ...theme.typography.labelMedium,
    }),
    labelContainer: css({
      display: 'flex',
    }),
    icon: css({
      paddingRight: spacing.s8,
    }),
    label: css({
      ...theme.typography.bodyMedium1,
      color: theme.colors.systemGrayscale70,
    }),
    description: css({
      '> p': {
        margin: '6px 0',
      },
      strong: {
        fontWeight: 600,
      },
    }),
  }
}

const RadioCard = ({
  name,
  label,
  value,
  checked = false,
  children,
  pillText,
  pillStyles,
  tooltipId,
  image,
  onClick,
  disabled = false,
  maxWidth = '337px',
  icon: Icon,
  ...restProps
}: RadioCardProps) => {
  const styles = useStyles({ checked, disabled, maxWidth })

  return (
    <div>
      <HovercardTooltip
        disabled={!tooltipId}
        tooltipContent={tooltipId && <FormattedMessage id={tooltipId} />}
        styles={{ anchor: { maxWidth } }}
      >
        <div
          role="button"
          tabIndex={0}
          css={[styles.container]}
          onClick={e => (disabled ? null : onClick?.(e))}
          onKeyPress={e => (disabled ? null : onClick?.(e))}
          data-testid={`radio-card-${value}`}
        >
          <div css={styles.radio}>
            <RadioSelectorBase
              value={value as RadioSelectorBaseProps['value']}
              checked={checked}
              disabled={disabled}
              {...restProps}
            />
          </div>
          <div>
            <div css={styles.labelContainer}>
              {Icon && <Icon css={styles.icon} className="button-icon" size={24} />}
              <label css={styles.label} htmlFor={name}>
                {label}
                {pillText && <span css={[styles.pill, pillStyles]}>{pillText}</span>}
              </label>
            </div>
            {children && <div css={styles.description}>{children}</div>}
          </div>
          {image && (
            <div data-testid="radio-card-image" css={styles.imageSection}>
              {image}
            </div>
          )}
        </div>
      </HovercardTooltip>
    </div>
  )
}

export default RadioCard
