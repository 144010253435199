import { ADS_API_PATH } from 'common/constants'
import { clearCurrentAccountId, setCurrentAccountId } from 'common/currentAccountHelper'
import { setFlippers } from 'common/sessionStorage'
import { MfaFactor } from 'components/MultiFactorAuth/utils/multiFactorAuth.types'
import { AdUsersSessionsApi } from 'service/apis/AdUsersSessionsApi'
import { setCsrfToken } from 'service/apis/getConfiguration'
import { PostAuthRequest } from 'service/openapi/__codegen__/apis/AdUsersSessionsApi'
import { AuthCreateResponse } from 'service/openapi/__codegen__/models/AuthCreateResponse'
import { AuthShowResponseDataAttributesCurrentAccountExchangeNameEnum as ExchangeNames } from 'service/openapi/__codegen__/models/AuthShowResponseDataAttributesCurrentAccount'
import {
  GetAccountPaymentResponseDataAttributesNextPaymentOption as NextPaymentOption,
  GetAccountPaymentResponseDataAttributesNextPaymentOptionPaymentOptionEnum as PaymentTypes,
} from 'service/openapi/__codegen__/models/GetAccountPaymentResponseDataAttributesNextPaymentOption'
import {
  GetAdminAccountsResponseDataAttributesAccountTypeEnum as AccountTypes,
  GetAdminAccountsResponseDataAttributesServingTypeEnum as ServingTypes,
  GetAdminAccountsResponseDataAttributesExternalMarketplaceEnum as ExternalMarketplaces,
} from 'service/openapi/__codegen__/models/GetAdminAccountsResponseDataAttributes'
import { GetAuthParamDataAttributesFeatureHints } from 'service/openapi/__codegen__/models/GetAuthParamDataAttributesFeatureHints'
import { GetAuthParamDataAttributesFeatureReminders } from 'service/openapi/__codegen__/models/GetAuthParamDataAttributesFeatureReminders'
import { PaymentStatuses } from 'service/types'
import Client, { ClientRequestHeaders } from './client'

export const UUID_REGEX = '[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}'

/**
 * You may be deeplinked to a page that belongs an Account other that your `last_viewed_account_id`.
 * In this case, we want to pass that info to the backend, so it knows this session is viewing another Account.
 * This function should check your URL and parse out any identifiers so they can be sent to the backend.
 * The backend then looks at this data, and determines if you have access to the Account.
 */
export const getAccountOverrideData = () => {
  const metadata: ClientRequestHeaders = {}

  // Check if we are on a featured product `/campaign` page
  const campaignPage = window.location.pathname.match(`/(display/|email/|)campaign/(${UUID_REGEX})`)
  const promotionPage = window.location.pathname.match(`/promotions/group/(${UUID_REGEX})`)
  const brandPage = window.location.pathname.match(`/brand_pages/(${UUID_REGEX})`)

  if (campaignPage) {
    const [, , campaign] = campaignPage
    metadata.Campaign = campaign
  }

  if (promotionPage) {
    const [, promotionCampaign] = promotionPage
    metadata.Campaign = promotionCampaign
  }

  if (brandPage) {
    const [, page] = brandPage
    metadata['Brand-Page-ID'] = page
  }

  return metadata
}

// eslint-disable-next-line no-restricted-syntax
export enum SupportedCountryId {
  UNITED_STATES = 840,
  CANADA = 124,
}

interface CurrentAccountDetails {
  id: number
  uuid: string
  name: string
  countryId: SupportedCountryId
  paymentStatus: PaymentStatuses
  activePaymentOption: PaymentTypes
  nextPaymentOption: NextPaymentOption | null
  canAccessProductLibrary: boolean
  canCreateProductUpdateRequests: boolean
  canAccessInsightsPortal: boolean
  canAccessCustomerAndBasketShare: boolean
  canAccessReservationCampaignsReporting: boolean
  accountType: AccountTypes
  alcohol: boolean
  servingType: ServingTypes
  createdAt: string
  isInHouse: boolean
  exchangeName: ExchangeNames
  externalMarketplace?: ExternalMarketplaces
  agencyId?: number
}

export interface AdUser {
  currentAccount: CurrentAccountDetails
  accountIdChanged?: boolean
  singleSignOnEnabled?: boolean
  userAccounts?: number
  email: string
  firstName: null | string
  lastName: null | string
  userType: string
  userId: number
  userUuid: string
  sessionId?: string
  flippers?: string[]
  mfaFactors?: MfaFactor[]
  activeVariants: Record<string, string>
}

interface CurrentAccountDetails {
  paymentStatus: PaymentStatuses
  nextPaymentOption: NextPaymentOption | null
}

export interface AdUserWithCapabilities extends AdUser {
  capabilities: Record<string, boolean>
  featureHints?: GetAuthParamDataAttributesFeatureHints[]
  featureReminders?: GetAuthParamDataAttributesFeatureReminders[]
}

export interface AuthResponseWithCapabilities {
  data: {
    attributes: AdUserWithCapabilities
    id: string
    type: string
  }
  meta: {
    csrfToken?: string
    status: number
  }
}

export interface AuthResponse {
  data: {
    attributes: AdUser
    id: string
    type: string
  }
  meta: {
    csrfToken?: string
    status: number
  }
}

class AuthClientImpl extends Client {
  public authenticated: Promise<boolean>

  constructor(successRequired = false) {
    super(successRequired)
    this.ADS_API_PATH = `${ADS_API_PATH}/auth`
    this.authenticated = new Promise((resolve, reject) => {
      try {
        resolve(true)
      } catch (err) {
        reject(err)
      }
    })
  }

  getUserInfo = () => {
    return this.performRequest<AuthResponseWithCapabilities>(
      {
        method: 'get',
      },
      {
        headers: getAccountOverrideData(),
      }
    )
      .then(res => {
        return Promise.all([res, import('ahoy.js')])
      })
      .then(([res, ahoy]) => {
        // Save flippers in session storage.
        // These will be used by Cypress to check what features to test
        if (res.data.attributes.flippers) {
          setFlippers(res.data.attributes.flippers)
        }
        const csrf = res.meta.csrfToken
        if (csrf) {
          Client.setCSRFToken(csrf)
          setCsrfToken(csrf)
          ahoy.default.configure({ headers: { 'X-CSRF-Token': csrf } })
        }

        const currentAccountId = res.data.attributes.currentAccount?.id
        if (currentAccountId) {
          setCurrentAccountId(currentAccountId)
        }

        Promise.resolve(this.authenticated)

        return res
      })
  }

  postAuth = (requestBody: PostAuthRequest): Promise<AuthCreateResponse> => {
    return AdUsersSessionsApi.postAuth(requestBody).then(res => {
      if (res?.meta?.status === 200) {
        document.cookie = 'authenticated=true'
      }
      return res
    })
  }

  logout = (): Promise<AuthResponse> => {
    return this.performRequest<AuthResponse>({
      method: 'delete',
    }).then(res => {
      if (res?.meta?.status === 200) {
        document.cookie = 'authenticated=; expires=Thu, 01 Jan 1970 00:00:00 GMT'
      }
      clearCurrentAccountId()
      return res
    })
  }
}

/* A singleton is exported to prevent multiple instances
 * from existing.
 */
export const AuthClient = new AuthClientImpl()
