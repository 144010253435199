// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo } from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage } from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile } from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb } from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageWebToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile } from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail } from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile } from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb } from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeAssetsVideo } from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsVideo'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsVideoFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsVideoFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsVideoToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeAssetsVideo'

/**
 *
 * @export
 * @interface AdminDisplayAdGroupResponseDataAttributesCreativeAssets
 */
export interface AdminDisplayAdGroupResponseDataAttributesCreativeAssets {
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  topImageWeb?: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWeb
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  imageWeb?: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageWeb
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  imageMobile?: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobile
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  topImageMobile?: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobile
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  squareImageMobile?: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobile
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  heroImage?: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImage
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  brandLogo?: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogo
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeAssetsVideo}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  video?: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsVideo
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreativeAssets
   */
  thumbnail?: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnail
}

/**
 * Check if a given object implements the AdminDisplayAdGroupResponseDataAttributesCreativeAssets interface.
 */
export function instanceOfAdminDisplayAdGroupResponseDataAttributesCreativeAssets(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function AdminDisplayAdGroupResponseDataAttributesCreativeAssetsFromJSON(
  json: any
): AdminDisplayAdGroupResponseDataAttributesCreativeAssets {
  return AdminDisplayAdGroupResponseDataAttributesCreativeAssetsFromJSONTyped(json, false)
}

export function AdminDisplayAdGroupResponseDataAttributesCreativeAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdminDisplayAdGroupResponseDataAttributesCreativeAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    topImageWeb: !exists(json, 'top_image_web')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebFromJSON(
          json['top_image_web']
        ),
    imageWeb: !exists(json, 'image_web')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageWebFromJSON(json['image_web']),
    imageMobile: !exists(json, 'image_mobile')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileFromJSON(
          json['image_mobile']
        ),
    topImageMobile: !exists(json, 'top_image_mobile')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileFromJSON(
          json['top_image_mobile']
        ),
    squareImageMobile: !exists(json, 'square_image_mobile')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileFromJSON(
          json['square_image_mobile']
        ),
    heroImage: !exists(json, 'hero_image')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageFromJSON(
          json['hero_image']
        ),
    brandLogo: !exists(json, 'brand_logo')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoFromJSON(
          json['brand_logo']
        ),
    video: !exists(json, 'video')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeAssetsVideoFromJSON(json['video']),
    thumbnail: !exists(json, 'thumbnail')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailFromJSON(json['thumbnail']),
  }
}

export function AdminDisplayAdGroupResponseDataAttributesCreativeAssetsToJSON(
  value?: AdminDisplayAdGroupResponseDataAttributesCreativeAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    top_image_web: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageWebToJSON(
      value.topImageWeb
    ),
    image_web: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageWebToJSON(
      value.imageWeb
    ),
    image_mobile: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsImageMobileToJSON(
      value.imageMobile
    ),
    top_image_mobile: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsTopImageMobileToJSON(
      value.topImageMobile
    ),
    square_image_mobile:
      AdminDisplayAdGroupResponseDataAttributesCreativeAssetsSquareImageMobileToJSON(
        value.squareImageMobile
      ),
    hero_image: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsHeroImageToJSON(
      value.heroImage
    ),
    brand_logo: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsBrandLogoToJSON(
      value.brandLogo
    ),
    video: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsVideoToJSON(value.video),
    thumbnail: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsThumbnailToJSON(
      value.thumbnail
    ),
  }
}
