// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties
 */
export interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties {
  /**
   *
   * @type {string}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties
   */
  type: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesTypeEnum
  /**
   * Products IDs
   * @type {Array<string>}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties
   */
  itemGrid: Array<string>
}

/**
 * @export
 */
export const PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesTypeEnum =
  {
    ProductCollection: 'product_collection',
  } as const
export type PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesTypeEnum =
  typeof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesTypeEnum[keyof typeof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesTypeEnum]

/**
 * Check if a given object implements the PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties interface.
 */
export function instanceOfPostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'itemGrid' in value

  return isInstance
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties {
  return PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSONTyped(
    json,
    false
  )
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    itemGrid: json['item_grid'],
  }
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsPropertiesToJSON(
  value?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridCollectionsProperties | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    item_grid: value.itemGrid,
  }
}
