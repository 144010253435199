import { getCreativeSchema } from 'common/common-definitions/creative'
import {
  areConditionallyRequiredFieldsMissing,
  areEveryFieldsMissing,
  areSomeFieldsMissing,
} from 'common/formikUtils'
import { isDisplayAdGroup, isReservationAdGroup } from 'pages/DisplayProduct/common/utils/utils'
import {
  AD_GROUP_NAME_FIELD_NAME,
  BRAND_PAGE_ID_FIELD_NAME,
  BRAND_PAGE_HERO_BANNER_ALT_FIELD_NAME,
  BRAND_PAGE_HERO_BANNER_MOBILE_UPLOADED_URI_FIELD_NAME,
  BRAND_PAGE_HERO_BANNER_DESKTOP_UPLOADED_URI_FIELD_NAME,
  CREATIVE_BANNER_ALT_FIELD_NAME,
  CREATIVE_BANNER_MOBILE_UPLOADED_URI_FIELD_NAME,
  CREATIVE_BANNER_WEB_UPLOADED_URI_FIELD_NAME,
  CREATIVE_BRAND_LOGO_ALT_FIELD_NAME,
  CREATIVE_BRAND_LOGO_UPLOADED_URI_FIELD_NAME,
  CREATIVE_HERO_IMAGE_ALT_FIELD_NAME,
  CREATIVE_HERO_IMAGE_UPLOADED_URI_FIELD_NAME,
  CREATIVE_THUMBNAIL_IMAGE_UPLOADED_URI_FIELD_NAME,
  CREATIVE_VIDEO_UPLOADED_URI_FIELD_NAME,
  CREATIVE_THUMBNAIL_IMAGE_ALT_FIELD_NAME,
  CREATIVE_TYPE,
  DEFAULT_BID_FIELD_NAME,
  TAGLINE_FIELD_NAME,
  TARGETING_TYPE_FIELD_NAME,
  URL_CLICK_URL_FIELD_NAME,
  RECIPE_ID_FIELD_NAME,
  COLLECTIONS_FIELD_NAME,
} from 'pages/DisplayProduct/DisplayAdGroup/constants'
import { DisplayAdGroupBackendData } from 'pages/DisplayProduct/DisplayAdGroup/displayAdGroup.types'
import { ReservationAdGroupBackendData } from 'pages/ReservationCampaigns/types'
import { AdminDisplayAdGroupResponseDataAttributesCreativeTypeEnum as CreativeTypeEnum } from 'service/openapi/__codegen__/models/AdminDisplayAdGroupResponseDataAttributesCreative'
import { PutDisplayCampaignsIdParamBlockHeroBannerDisplayTypeEnum } from 'service/openapi/__codegen__/models/PutDisplayCampaignsIdParamBlockHeroBannerDisplay'

// Fields from Formik
const commonRequiredFormFields = (isReservation?: boolean) => {
  const fields = [AD_GROUP_NAME_FIELD_NAME, CREATIVE_TYPE]
  if (!isReservation) {
    fields.push(DEFAULT_BID_FIELD_NAME)
    fields.push(TARGETING_TYPE_FIELD_NAME)
  }
  return fields
}

export const getRequiredFormFields = (creativeType: string, isReservation?: boolean): string[] => {
  const creativeSchema = getCreativeSchema(creativeType)

  // Use a set to avoid duplicates
  const requiredFormFields = new Set(commonRequiredFormFields(isReservation))

  /* Creative properties */
  if (creativeSchema.properties.hasTagline()) {
    requiredFormFields.add(TAGLINE_FIELD_NAME)
  }

  /* Creative actions */
  if (creativeSchema.actions.hasBrandPageClick()) {
    requiredFormFields.add(BRAND_PAGE_ID_FIELD_NAME)
  }

  if (creativeSchema.actions.hasUrlClick()) {
    requiredFormFields.add(URL_CLICK_URL_FIELD_NAME)
  }

  if (creativeSchema.actions.hasRecipeClick()) {
    requiredFormFields.add(RECIPE_ID_FIELD_NAME)
  }

  if (creativeSchema.actions.hasCollectionGrid()) {
    requiredFormFields.add(COLLECTIONS_FIELD_NAME)
  }

  /* Creative assets */
  if (creativeSchema.assets.hasTopImageMobile() || creativeSchema.assets.hasImageWeb()) {
    requiredFormFields.add(CREATIVE_BANNER_ALT_FIELD_NAME)
    requiredFormFields.add(CREATIVE_BANNER_MOBILE_UPLOADED_URI_FIELD_NAME)
  }

  if (creativeSchema.assets.hasTopImageWeb() || creativeSchema.assets.hasImageMobile()) {
    requiredFormFields.add(CREATIVE_BANNER_ALT_FIELD_NAME)
    requiredFormFields.add(CREATIVE_BANNER_WEB_UPLOADED_URI_FIELD_NAME)
  }

  if (creativeSchema.assets.hasBrandLogo()) {
    requiredFormFields.add(CREATIVE_BRAND_LOGO_ALT_FIELD_NAME)
    requiredFormFields.add(CREATIVE_BRAND_LOGO_UPLOADED_URI_FIELD_NAME)
  }

  if (creativeSchema.assets.hasHeroImage()) {
    requiredFormFields.add(CREATIVE_HERO_IMAGE_ALT_FIELD_NAME)
    requiredFormFields.add(CREATIVE_HERO_IMAGE_UPLOADED_URI_FIELD_NAME)
  }

  if (creativeSchema.assets.hasThumbnail()) {
    requiredFormFields.add(CREATIVE_THUMBNAIL_IMAGE_ALT_FIELD_NAME)
    requiredFormFields.add(CREATIVE_THUMBNAIL_IMAGE_UPLOADED_URI_FIELD_NAME)
  }

  if (creativeSchema.assets.hasVideo()) {
    requiredFormFields.add(CREATIVE_VIDEO_UPLOADED_URI_FIELD_NAME)
  }

  return Array.from(requiredFormFields)
}

export const conditionallyRequiredFormFields = [
  BRAND_PAGE_HERO_BANNER_ALT_FIELD_NAME,
  BRAND_PAGE_HERO_BANNER_MOBILE_UPLOADED_URI_FIELD_NAME,
  BRAND_PAGE_HERO_BANNER_DESKTOP_UPLOADED_URI_FIELD_NAME,
]

// Fields from ad group response
const responseFieldPrefix = 'attributes'
const commonRequiredResponseFields = (isReservation?: boolean) => {
  const fields = [`${responseFieldPrefix}.name`, `${responseFieldPrefix}.creative.type`]
  if (!isReservation) {
    fields.push(`${responseFieldPrefix}.defaultBid`)
  }
  return fields
}

const requiredResponseFields = (
  isReservation?: boolean
): { [key: string]: string[] | undefined } => {
  const commonRequired = commonRequiredResponseFields(isReservation)

  return {
    [CreativeTypeEnum.SearchBannerV1]: [
      ...commonRequired,
      `${responseFieldPrefix}.creative.assets.topImageMobile.altText`,
      `${responseFieldPrefix}.creative.assets.topImageWeb.altText`,
      `${responseFieldPrefix}.creative.assets.topImageMobile.previewUrl`,
      `${responseFieldPrefix}.creative.assets.topImageWeb.previewUrl`,
      `${responseFieldPrefix}.creative.actions.bannerClick.brandPageClick.brandPageId`,
    ],
    [CreativeTypeEnum.PromotedAisleV1]: [
      ...commonRequired,
      `${responseFieldPrefix}.creative.properties.tagline`,
      `${responseFieldPrefix}.creative.actions.mainItemGrid.itemGrid`,
      `${responseFieldPrefix}.creative.assets.brandLogo.altText`,
      `${responseFieldPrefix}.creative.assets.brandLogo.previewUrl`,
      `${responseFieldPrefix}.creative.assets.heroImage.altText`,
      `${responseFieldPrefix}.creative.assets.heroImage.previewUrl`,
      `${responseFieldPrefix}.creative.actions.bannerClick.brandPageClick.brandPageId`,
    ],
    [CreativeTypeEnum.PromotedAisleV2]: [
      ...commonRequired,
      `${responseFieldPrefix}.creative.properties.tagline`,
      `${responseFieldPrefix}.creative.actions.mainItemGrid.itemGrid`,
      `${responseFieldPrefix}.creative.assets.heroImage.altText`,
      `${responseFieldPrefix}.creative.assets.heroImage.previewUrl`,
      `${responseFieldPrefix}.creative.actions.bannerClick.brandPageClick.brandPageId`,
    ],
    [CreativeTypeEnum.ShoppableVideoV1]: [
      ...commonRequired,
      `${responseFieldPrefix}.creative.properties.tagline`,
      `${responseFieldPrefix}.creative.actions.mainItemGrid.itemGrid`,
      `${responseFieldPrefix}.creative.assets.brandLogo.altText`,
      `${responseFieldPrefix}.creative.assets.brandLogo.previewUrl`,
      `${responseFieldPrefix}.creative.assets.video.previewUrl`,
      `${responseFieldPrefix}.creative.assets.thumbnail.altText`,
      `${responseFieldPrefix}.creative.assets.thumbnail.previewUrl`,
      `${responseFieldPrefix}.creative.actions.bannerClick.brandPageClick.brandPageId`,
    ],
    [CreativeTypeEnum.ImageBannerV3]: [
      ...commonRequired,
      `${responseFieldPrefix}.creative.assets.topImageMobile.altText`,
      `${responseFieldPrefix}.creative.assets.topImageWeb.altText`,
      `${responseFieldPrefix}.creative.assets.topImageMobile.previewUrl`,
      `${responseFieldPrefix}.creative.assets.topImageWeb.previewUrl`,
      `${responseFieldPrefix}.creative.actions.bannerClick.urlClick.url`,
    ],
    [CreativeTypeEnum.OccasionV1]: [
      ...commonRequired,
      `${responseFieldPrefix}.creative.properties.tagline`,
      `${responseFieldPrefix}.creative.assets.brandLogo.altText`,
      `${responseFieldPrefix}.creative.assets.brandLogo.previewUrl`,
      `${responseFieldPrefix}.creative.assets.heroImage.altText`,
      `${responseFieldPrefix}.creative.assets.heroImage.previewUrl`,
      `${responseFieldPrefix}.creative.actions.bannerClick.brandPageClick.brandPageId`,
      // Validate that there are at least 2 collections (1 sponsored and 1 non-sponsored)
      `${responseFieldPrefix}.creative.actions.mainCollectionGrid.collections[0].name`,
      `${responseFieldPrefix}.creative.actions.mainCollectionGrid.collections[0].properties.itemGrid`,
      `${responseFieldPrefix}.creative.actions.mainCollectionGrid.collections[0].sponsored`,
      `${responseFieldPrefix}.creative.actions.mainCollectionGrid.collections[1].name`,
      `${responseFieldPrefix}.creative.actions.mainCollectionGrid.collections[1].properties.itemGrid`,
    ],
  }
}

const conditionallyRequiredResponseFields = (heroBannerBlockIndex: number | undefined) => [
  `${responseFieldPrefix}.brandPage.blocks[${heroBannerBlockIndex}].assets.desktop.altText`,
  `${responseFieldPrefix}.brandPage.blocks[${heroBannerBlockIndex}].assets.desktop.uploadedUri`,
  `${responseFieldPrefix}.brandPage.blocks[${heroBannerBlockIndex}].assets.mobile.altText`,
  `${responseFieldPrefix}.brandPage.blocks[${heroBannerBlockIndex}].assets.mobile.uploadedUri`,
]
const targetingResponseFields = [
  `${responseFieldPrefix}.targetingStrategy.targetingRule`,
  `${responseFieldPrefix}.targetingStrategy.keywords`,
]

export const areResponseFieldsMissing = (
  adGroup: DisplayAdGroupBackendData | ReservationAdGroupBackendData
) => {
  const heroBannerBlockIndex = isDisplayAdGroup(adGroup)
    ? adGroup.attributes.brandPage?.blocks.findIndex(brandPageBlock => {
        return (
          brandPageBlock.type ===
          PutDisplayCampaignsIdParamBlockHeroBannerDisplayTypeEnum.BlockHeroBannerV1
        )
      })
    : undefined

  const requiredFields = requiredResponseFields(isReservationAdGroup(adGroup))[
    adGroup.attributes.creative?.type
  ]
  const conditionallyRequiredFields = conditionallyRequiredResponseFields(heroBannerBlockIndex)

  const targetingFieldsMissing = isReservationAdGroup(adGroup)
    ? false
    : areEveryFieldsMissing(adGroup, targetingResponseFields)

  return (
    areSomeFieldsMissing(adGroup, requiredFields) ||
    areConditionallyRequiredFieldsMissing(adGroup, conditionallyRequiredFields) ||
    targetingFieldsMissing
  )
}
