import { Global, css } from '@emotion/react'
import { RadioSelectorBase } from '@instacart/ids-core'
// eslint-disable-next-line no-restricted-imports
import { Slide } from 'ic-snacks'
import moment from 'moment'
import { Component, ChangeEvent } from 'react'
import ClickOutside from 'react-click-outside'
import DayPicker, { DateUtils, DayModifiers, Modifier, RangeModifier } from 'react-day-picker'
import Analytics from 'common/analytics/Analytics'
import layers from 'common/layers'
import { DATE_LABEL_OPTIONS, DateLabel, SelectedDays, InsightsDateLabel } from 'common/types'
import { formatDate, getPickerDateRange, SHORT_DATE_FORMAT } from 'common/utils-moment'
import { DateRangePickerStyles } from 'components/DateRangePicker/DateRangePickerStyles'
import FormattedMessage from 'components/FormattedMessage'
import {
  PrimaryButton,
  FilterDropdownButton,
  SecondaryButton,
  TertiaryButton,
} from 'components/ids-ads/molecules'
import { TimespanEnum, WeekStartEnum } from 'pages/Insight/common.enums'
import { todaysPSTDateString } from '../organisms/AnalyticsChart/utils/todaysPSTDateString'
import {
  DATE_LABEL_CUSTOM,
  disableApplyButton,
  getDateRangeForDailyPeriod,
  getSelectedDays,
} from './DateRangePicker.utils'

export interface DateRangePickerProps {
  selectedLabel: DateLabel | string
  dateRange: SelectedDays
  onDateSelect: (selectedLabel: DateLabel | string, selected: SelectedDays) => void
  onCancelClick?: () => void
  onTriggerClick?: () => void
  numberOfMonths?: number
  overlayPosition?: 'right' | 'left'
  dateOptions?: DateLabel[] | InsightsDateLabel[]
  disabledDays?: Modifier | Modifier[]
  weekStart?: WeekStartEnum
  timespan?: TimespanEnum
  buttonType?: 'FilterSelect-deprecated' | 'Secondary' | 'Tertiary'
  enableFutureDays?: boolean
  children?: React.ReactNode
}

interface DateRangePickerState {
  open: boolean
  selectedLabel: DateLabel | string
  selectedDays: SelectedDays
}

const styles = {
  selectWrapper: css({
    position: 'relative',
  }),
  selectTriggerOverlay: css({
    position: 'absolute',
    bottom: 0,
    transform: 'translateY(100%)',
    zIndex: layers[1],
    '&.right': {
      right: 0,
    },
    '&.left': {
      left: 0,
    },
  }),
  optionsWrapper: css({
    backgroundColor: '#FFFFFF',
    border: `solid 1px #BDBDBD`,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0 0 1em 1em',
    position: 'relative',
    width: 380,
    '&.hidden': {
      display: 'none',
    },
  }),
  selectButtons: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '1em',
  }),
  radioButtons: css({
    marginTop: '1em',
  }),
  optionsContent: css({
    display: 'flex',
    flexWrap: 'nowrap',
  }),
  options: css({
    display: 'flex',
    alignItems: 'center',
  }),
}

export class DateRangePicker extends Component<DateRangePickerProps, DateRangePickerState> {
  static defaultProps: Partial<DateRangePickerProps> = {
    overlayPosition: 'right',
    buttonType: 'Tertiary',
    weekStart: WeekStartEnum.Sunday,
    enableFutureDays: false,
  }

  constructor(props: DateRangePickerProps) {
    super(props)
    this.state = {
      selectedLabel: props.selectedLabel,
      selectedDays: this.getInitialDateRangeState(props.dateRange),
      open: false,
    }
  }

  componentDidUpdate(prevProps: Readonly<DateRangePickerProps>) {
    // Update internal state when date range is updated externally
    if (
      prevProps.selectedLabel !== this.props.selectedLabel ||
      prevProps.timespan !== this.props.timespan
    ) {
      const { dateRange, selectedLabel, timespan } = this.props
      const newSelectedLabel = timespan === TimespanEnum.Day ? DATE_LABEL_CUSTOM : selectedLabel

      // Since we're calling setState conditionally this is not an issue
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => {
        let selectedDays: SelectedDays = this.getInitialDateRangeState(dateRange)

        if (timespan) {
          selectedDays = getSelectedDays(selectedLabel, timespan, prevState.selectedDays)
        }

        return {
          selectedLabel: newSelectedLabel,
          selectedDays,
        }
      })
    }
  }

  handleTriggerClick = () => {
    const { open } = this.state
    const { onTriggerClick } = this.props
    this.setState({ open: !open })

    onTriggerClick?.()
  }

  handleCancel = (fromClickOutside = false) => {
    const { onCancelClick, selectedLabel } = this.props
    this.setState({
      selectedLabel,
      open: false,
    })

    if (!fromClickOutside) {
      onCancelClick?.()
    }
  }

  handleApply = () => {
    const { onDateSelect } = this.props
    const { selectedLabel, selectedDays } = this.state
    const { from, to } = selectedDays

    Analytics.mergeCommonParams({
      timeframeStart: from && formatDate(from),
      timeframeEnd: to && formatDate(to),
    })

    if (!from && !to) {
      this.setState({ selectedLabel: 'Lifetime', selectedDays: {} })
      onDateSelect('Lifetime', {})
    } else {
      onDateSelect(selectedLabel, selectedDays)
    }

    this.setState({ open: false })
  }

  handleCalendarDayClick = (day: Date) => {
    const { selectedDays } = this.state

    if (selectedDays.from && selectedDays.to) {
      const result = getDateRangeForDailyPeriod(day, {
        from: selectedDays.from,
        to: selectedDays.to,
      })

      this.setState({
        selectedDays: result,
      })
    }
  }

  handleDayClick = (day: Date, modifiers: DayModifiers) => {
    if (
      (!this.props.enableFutureDays && DateUtils.isDayAfter(day, new Date())) ||
      modifiers.disabled
    ) {
      return
    }
    const { selectedLabel, selectedDays } = this.state
    const currentLabelRange = getPickerDateRange({
      dateRangeOption: selectedLabel,
      fromDate: todaysPSTDateString(),
      weekStart: this.props.weekStart,
    })
    const newSelected = DateUtils.addDayToRange(day, selectedDays as RangeModifier)

    const isCustom =
      currentLabelRange.from !== newSelected.from || currentLabelRange.to !== newSelected.to

    this.setState({
      selectedDays: { from: newSelected.from, to: newSelected.to ?? newSelected.from },
      selectedLabel: isCustom
        ? `${formatDate(newSelected.from, SHORT_DATE_FORMAT)} - ${formatDate(
            newSelected.to ?? newSelected.from,
            SHORT_DATE_FORMAT
          )}`
        : selectedLabel,
    })

    if (this.props.timespan === TimespanEnum.Day) {
      this.handleCalendarDayClick(day)
    }
  }

  handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    const radioValue = e.target.value
    const dateRange = getPickerDateRange({
      dateRangeOption: radioValue,
      fromDate: todaysPSTDateString(),
      weekStart: this.props.weekStart,
      enableFutureDays: this.props.enableFutureDays,
    })
    this.setState({
      selectedLabel: radioValue,
      selectedDays: dateRange,
    })
  }

  handleClickOutside = () => {
    this.handleCancel(true)
  }

  getInitialDateRangeState = ({ from, to }: SelectedDays) => ({
    from,
    to,
  })

  renderDateRangeOptions = () => {
    const { open, selectedLabel, selectedDays } = this.state
    const { children, dateOptions, disabledDays, numberOfMonths, timespan, enableFutureDays } =
      this.props
    const { from, to } = selectedDays
    const modifiers = {
      start: from,
      end: to,
    }
    const dateLabelOptions = dateOptions || DATE_LABEL_OPTIONS

    const radioButtons = dateLabelOptions.map(option => (
      <div css={styles.options}>
        <RadioSelectorBase
          key={option}
          id={option}
          value={option}
          checked={
            option === DATE_LABEL_CUSTOM
              ? selectedLabel === DATE_LABEL_CUSTOM ||
                !(dateLabelOptions as string[]).includes(selectedLabel)
              : selectedLabel === option
          }
          disabled={timespan === TimespanEnum.Day && option !== DATE_LABEL_CUSTOM}
          onChange={this.handleRadioChange}
        />
        <label htmlFor={option}>{option}</label>
      </div>
    ))

    const formatDisabledDays = !enableFutureDays
      ? timespan === TimespanEnum.Day
        ? disabledDays && {
            after: moment().subtract(4, 'day').toDate(),
          }
        : disabledDays || {
            after: new Date(),
          }
      : undefined

    if (open) {
      return (
        <div css={styles.optionsContent}>
          <div css={styles.radioButtons}>
            {radioButtons} {children}
          </div>
          <DayPicker
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            selectedDays={[from, { from, to } as RangeModifier]}
            modifiers={modifiers}
            onDayClick={this.handleDayClick}
            disabledDays={formatDisabledDays}
            initialMonth={
              !(dateLabelOptions as string[]).includes(selectedLabel) ? from : new Date()
            }
            numberOfMonths={numberOfMonths}
            month={to || new Date()}
          />
        </div>
      )
    }
    return null
  }

  render() {
    const { overlayPosition, buttonType, timespan, weekStart } = this.props
    const { open, selectedDays, selectedLabel } = this.state

    const applyDisabled =
      timespan && disableApplyButton(selectedDays, timespan, weekStart as WeekStartEnum)

    const filterButtonProps = {
      tabIndex: 0,
      onClick: this.handleTriggerClick,
      onKeyDown: this.handleTriggerClick,
      open,
      'data-testid': 'date-range-picker',
    }
    const otherButtonProps = {
      ...filterButtonProps,
      openIndicator: true,
    }

    return (
      <ClickOutside onClickOutside={this.handleClickOutside}>
        <div css={styles.selectWrapper}>
          {buttonType === 'FilterSelect-deprecated' && (
            <FilterDropdownButton {...filterButtonProps}>{selectedLabel}</FilterDropdownButton>
          )}
          {buttonType === 'Secondary' && (
            <SecondaryButton {...otherButtonProps}>{selectedLabel}</SecondaryButton>
          )}
          {buttonType === 'Tertiary' && (
            <TertiaryButton {...otherButtonProps} compact>
              {selectedLabel}
            </TertiaryButton>
          )}
          <div
            css={[styles.selectTriggerOverlay, !open && { width: '0px' }]}
            className={overlayPosition}
          >
            <Slide in={open} axis="y">
              <div css={styles.optionsWrapper}>
                {this.renderDateRangeOptions()}
                {applyDisabled && (
                  <div data-testid="disclaimer">
                    <FormattedMessage id="pages.insights.sales.filter.dateRange.day.disclaimer" />
                  </div>
                )}
                <div css={styles.selectButtons} data-testid="date-range-select-buttons">
                  <TertiaryButton compact onClick={() => this.handleCancel()}>
                    <FormattedMessage id="common.cancel" />
                  </TertiaryButton>
                  <div css={{ width: 15 }} />
                  <PrimaryButton compact disabled={applyDisabled} onClick={this.handleApply}>
                    <FormattedMessage id="common.apply" />
                  </PrimaryButton>
                </div>
              </div>
            </Slide>
          </div>
          <DateRangePickerStyles />
          <Global
            styles={css`
              .DayPicker {
                background-color: #ffffff;
                font-size: 14px;
              }

              .DayPicker-Caption {
                text-align: center;
              }

              .DayPicker-NavBar span:first-of-type {
                left: 1.5em;
                right: auto;
              }

              .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                background-color: #f0f8ff;
                color: #4a90e2;
              }
              .DayPicker-Day {
                border-radius: 0;
              }
              .DayPicker-Day--start {
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
              }
              .DayPicker-Day--end {
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
              }
              .DayPicker-Day--disabled {
                cursor: not-allowed;
              }
            `}
          />
        </div>
      </ClickOutside>
    )
  }
}
