import { FunctionComponent, useState, useEffect, HTMLProps } from 'react'
import { PROVI_SELF_SERVE_TERMS_URL } from 'common/constants'
import useIntl from 'common/useIntl'
import FormattedMessage from 'components/FormattedMessage'
import PrivacyTerms from 'components/TermsAndNotices/PrivacyTerms'
import { useAuthContext, isProviAccount } from 'context'
import { getAccountClient } from 'service/account'
import { GetAccountDocumentFilenameEnum as AccountDocumentEnum } from 'service/openapi/__codegen__/apis/AccountsApi'
import { GetAdminAccountsResponseDataAttributesAccountTypeEnum as AccountTypes } from 'service/openapi/__codegen__/models/GetAdminAccountsResponseDataAttributes'
import { TermsAndConditions } from '../molecules'

type CampaignCreationType = 'display' | 'featured_product' | 'video_display'

type TermsProps = Pick<HTMLProps<HTMLDivElement>, 'css' | 'children'> & {
  includeBudgetTerms?: boolean
  includeKeywordBiddingTerms?: boolean
  includeBidLandscapeDisclaimer?: boolean
  campaignCreationType?: CampaignCreationType
}

export const CampaignTermsAndConditions: FunctionComponent<React.PropsWithChildren<TermsProps>> = ({
  includeBudgetTerms,
  includeKeywordBiddingTerms,
  includeBidLandscapeDisclaimer,
  campaignCreationType = 'featured_product',
  css,
}) => {
  const { formatMessage } = useIntl()
  const authContext = useAuthContext()
  const [termsFPUrl, setTermsFPUrl] = useState('')
  const [termsDisplayUrl, setTermsDisplayUrl] = useState('')
  const [additionalTermsDisplayUrl, setAdditionalTermsDisplayUrl] = useState('')
  const [additionalTermsVideoDisplayUrl, setAdditionalTermsVideoDisplayUrl] = useState('')
  const [termsUrl, setTermsUrl] = useState('')
  const [isAlcohol, setIsAlcohol] = useState(false)
  const [isRTDAccount, setIsRTDAccount] = useState(false)
  const isProvi = isProviAccount(authContext)

  useEffect(() => {
    const accountId = authContext?.user?.currentAccount?.uuid || ''
    const isAgency = authContext?.user?.type === 'agency'

    const getAccount = async () => {
      const AccountClient = await getAccountClient()
      const res = await AccountClient.required.getAccount()

      const { alcohol } = res?.data?.attributes || {}
      const msaString = isAgency ? AccountDocumentEnum.AgencyMsa : AccountDocumentEnum.AdvertiserMsa

      setTermsUrl(AccountClient.required.documentURI(msaString))
      setTermsFPUrl(AccountClient.required.documentURI(AccountDocumentEnum.TermsAndConditions))
      setTermsDisplayUrl(AccountClient.required.documentURI(AccountDocumentEnum.DisplayMsa))
      setAdditionalTermsDisplayUrl(
        AccountClient.required.documentURI(AccountDocumentEnum.DisplayAdditionalTerms)
      )
      setAdditionalTermsVideoDisplayUrl(
        AccountClient.required.documentURI(AccountDocumentEnum.VideoDisplayAdditionalTerms)
      )
      setIsAlcohol(!!alcohol)
      setIsRTDAccount(authContext?.user?.currentAccount?.accountType === AccountTypes.AdvertiserRtd)
    }
    if (accountId) {
      getAccount()
    }
  }, [authContext])

  const ProviTermsAndConditions = () => {
    return (
      <TermsAndConditions color="systemGrayscale70" typography="bodySmall2" css={css}>
        {formatMessage(
          { id: 'pages.campaignPayment.legal.proviTerms' },
          {
            linkedTerms: (
              <a href={PROVI_SELF_SERVE_TERMS_URL} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="pages.campaignPayment.legal.proviTerms.name" />
              </a>
            ),
          }
        )}
      </TermsAndConditions>
    )
  }

  switch (campaignCreationType) {
    case 'display':
      if (isProvi) {
        return <ProviTermsAndConditions />
      }
      if (isAlcohol) {
        return (
          <TermsAndConditions color="systemGrayscale70" typography="bodySmall2" css={css}>
            {formatMessage(
              { id: 'pages.campaignPayment.legal.displayFullTermsAlcohol' },
              {
                linkedTerms: (
                  <a href={termsDisplayUrl} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="pages.campaignPayment.legal.searchBannerTermsAlcohol" />
                  </a>
                ),
              }
            )}
          </TermsAndConditions>
        )
      }
      return (
        <TermsAndConditions color="systemGrayscale70" typography="bodySmall2" css={css}>
          {isRTDAccount && (
            <>
              <FormattedMessage id="pages.campaignPayment.legal.shareDataForRTDAccount" />{' '}
            </>
          )}
          {formatMessage(
            { id: 'pages.campaignPayment.legal.displayFullTerms' },
            {
              submitForReview: (
                <FormattedMessage id="pages.campaignPayment.legal.submitAndReview" />
              ),
              linkedTerms: (
                <a href={termsDisplayUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="pages.campaignPayment.legal.display.searchBannerTerms" />
                </a>
              ),
              linkedAdditionalTerms: (
                <a href={additionalTermsDisplayUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="pages.campaignPayment.legal.display.searchBannerAdditionalTerms" />
                </a>
              ),
              privacyPolicy: (
                <a href="/privacy" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="common.privacyPolicy" />
                </a>
              ),
            }
          )}
        </TermsAndConditions>
      )
    case 'video_display':
      return (
        <TermsAndConditions color="systemGrayscale70" typography="bodySmall2" css={css}>
          {formatMessage(
            { id: 'pages.campaignPayment.legal.videoDisplay.videoDisplayFullTerms' },
            {
              submitForReview: (
                <FormattedMessage id="pages.campaignPayment.legal.submitAndReview" />
              ),
              linkedTerms: (
                <a href={termsDisplayUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="pages.campaignPayment.legal.videoDisplay.videoDisplayTerms" />
                </a>
              ),
              linkedAdditionalTerms: (
                <a href={additionalTermsVideoDisplayUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="pages.campaignPayment.legal.videoDisplay.videoDisplayAdditionalTerms" />
                </a>
              ),
              privacyPolicy: (
                <a href="/privacy" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="common.privacyPolicy" />
                </a>
              ),
            }
          )}
        </TermsAndConditions>
      )
    case 'featured_product':
      if (isProvi) {
        return <ProviTermsAndConditions />
      }
      if (isAlcohol) {
        return (
          <TermsAndConditions color="systemGrayscale70" typography="bodySmall2" css={css}>
            <FormattedMessage id="pages.campaignPayment.legal.main1" />
            <a href={termsUrl} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="pages.campaignPayment.legal.termsAndConditions" />
            </a>{' '}
            <FormattedMessage id="pages.campaignPayment.legal.alcohol" />{' '}
          </TermsAndConditions>
        )
      }

      return (
        <TermsAndConditions color="systemGrayscale70" typography="bodySmall2" css={css}>
          {isRTDAccount && (
            <>
              <FormattedMessage id="pages.campaignPayment.legal.shareDataForRTDAccount" />{' '}
            </>
          )}
          {includeBudgetTerms && (
            <>
              <FormattedMessage id="pages.campaignPayment.legal.budget" />{' '}
            </>
          )}
          {includeBidLandscapeDisclaimer && (
            <>
              <FormattedMessage id="pages.campaignPayment.legal.bidLandscape" />
              <br />
              <br />
            </>
          )}
          <FormattedMessage id="pages.campaignPayment.legal.main1" />
          <a href={termsUrl} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="pages.campaignPayment.legal.termsAndConditions" />
          </a>{' '}
          <FormattedMessage id="pages.campaignPayment.legal.main2" />
          <a href={termsFPUrl} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="pages.campaignPayment.legal.sponsoredProductTerms" />
          </a>
          {'. '}
          <FormattedMessage id="pages.campaignPayment.legal.main3" />
          {includeKeywordBiddingTerms && (
            <FormattedMessage id="pages.campaignPayment.legal.keywordBidding1" />
          )}
          <FormattedMessage id="pages.campaignPayment.legal.main4" /> <PrivacyTerms />
        </TermsAndConditions>
      )
  }
}
