import { css } from '@emotion/react'
import { useTheme, Color, IssueIcon, InformationIcon, spacing } from '@instacart/ids-core'
import { toPx, useIntl } from 'common'
import { genericMessageDescriptorExists, uc } from 'common/utils'
import { DefinitionText } from 'components/ids-ads'
import { GenericMessageDescriptor } from 'locales/types'
import { StateMap, StateMapType, StateMapValueType, StateTooltipMap } from './StateMap'

export const DEFAULT_ICON_SIZE = 20

export type StatusPillProps = {
  color?: Color
  backgroundColor?: Color
  stateMap?: StateMapType
  tooltipContent?: JSX.Element
  tooltipMessageId?: GenericMessageDescriptor
  status?: string
  atRisk?: boolean
}

const useStyles = (color?: Color, backgroundColor?: Color, status?: StateMapValueType) => {
  const theme = useTheme()

  const statusColor = status ? status.color : color
  const statusBackgroundColor = status ? status.backgroundColor : backgroundColor

  return {
    message: css({
      whiteSpace: 'nowrap',
      ...theme.typography.bodySmall1,
    }),
    pill: css({
      ...theme.typography.disclaimerMedium,
      display: 'inline-block',
      padding: toPx`${2} ${8} ${3}`,
      borderRadius: 999,
      textTransform: 'uppercase',
      marginRight: spacing.s4,
      ...(statusColor ? { color: theme.colors[statusColor] } : {}),
      ...(statusBackgroundColor ? { background: theme.colors[statusBackgroundColor] } : {}),
    }),
    wrapper: css({
      display: 'flex',
      alignItems: 'center',
    }),
    anchor: {
      anchor: {
        display: 'flex',
        alignItems: 'center',
      },
    },
  }
}

export default function StatusPill({
  color,
  backgroundColor,
  status = 'UNKNOWN',
  stateMap = StateMap,
  tooltipContent,
  tooltipMessageId,
  atRisk,
}: StatusPillProps) {
  const intl = useIntl()
  const { genericFormatMessage } = intl
  const ucStatus = uc(status)
  const mappedStatus = ucStatus in stateMap ? stateMap[ucStatus] : undefined
  const styles = useStyles(color, backgroundColor, mappedStatus)

  const text = mappedStatus?.labelId || 'common.status.unknown' // If it exists in state map, use it. If not, status is unknown

  const Icon = atRisk ? IssueIcon : InformationIcon
  const iconColor = atRisk ? 'brandExpressRegular' : 'systemGrayscale50'
  const iconTestId = atRisk ? 'issue' : 'info'

  const defaultTooltip =
    ucStatus in StateTooltipMap ? genericFormatMessage(StateTooltipMap[ucStatus]) : tooltipContent

  const tooltip =
    tooltipMessageId && genericMessageDescriptorExists(tooltipMessageId, intl)
      ? genericFormatMessage(tooltipMessageId)
      : defaultTooltip

  const tooltipIcon = tooltip ? (
    <DefinitionText definitionContent={tooltip} styles={styles.anchor}>
      <Icon
        size={DEFAULT_ICON_SIZE}
        data-testid={`status-pill-${iconTestId}-icon`}
        color={iconColor}
      />
    </DefinitionText>
  ) : null

  return (
    <div css={styles.wrapper}>
      <span data-testid="status-pill" css={styles.pill}>
        <div data-testid={`status-pill-${status}`} css={styles.message}>
          {genericFormatMessage(text)}
        </div>
      </span>
      {tooltipIcon}
    </div>
  )
}
