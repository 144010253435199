// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets
 */
export interface GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets {
  /**
   * The id of the asset. Only needed for update
   * @type {string}
   * @memberof GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets
   */
  id?: string
  /**
   * The type of asset
   * @type {string}
   * @memberof GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets
   */
  type: GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssetsTypeEnum
  /**
   * URL for previewing, may change over time
   * @type {string}
   * @memberof GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets
   */
  previewUrl?: string
  /**
   * URI reference to the file used to determine if underlying asset has changed
   * @type {string}
   * @memberof GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets
   */
  uploadedUri: string
  /**
   * Alt text of asset
   * @type {string}
   * @memberof GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets
   */
  altText: string
  /**
   * The name of the file
   * @type {string}
   * @memberof GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets
   */
  uploadedFileName?: string
}

/**
 * @export
 */
export const GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssetsTypeEnum = {
  BlockHeroBannerLarge: 'block_hero_banner_large',
  BlockHeroBannerMedium: 'block_hero_banner_medium',
  BlockHeroBannerSmall: 'block_hero_banner_small',
  BlockHeroBannerXl: 'block_hero_banner_xl',
  BrandLogoCaper: 'brand_logo_caper',
  BrandLogoLarge: 'brand_logo_large',
  BrandLogoPromotedAisle: 'brand_logo_promoted_aisle',
  BrandLogoSmall: 'brand_logo_small',
  CaperImageDisplayBanner: 'caper_image_display_banner',
  CaperSpotlightAdHeroBanner: 'caper_spotlight_ad_hero_banner',
  EmailImagery: 'email_imagery',
  EmailProductImage: 'email_product_image',
  ImageAsideLarge: 'image_aside_large',
  ImageAsideSmall: 'image_aside_small',
  ImageBannerLarge: 'image_banner_large',
  ImageBannerSmall: 'image_banner_small',
  ImageDisplayBannerLarge: 'image_display_banner_large',
  ImageDisplayBannerSmall: 'image_display_banner_small',
  ImageDisplayBannerSquare: 'image_display_banner_square',
  ImageHeroBannerExtraLarge: 'image_hero_banner_extra_large',
  ImageHeroBannerLarge: 'image_hero_banner_large',
  ImageHeroBannerMedium: 'image_hero_banner_medium',
  ImageHeroBannerSmall: 'image_hero_banner_small',
  ImageProductLifestyleLarge: 'image_product_lifestyle_large',
  ImageProductLifestyleSmall: 'image_product_lifestyle_small',
  ImagePromotedAisleHeroBanner: 'image_promoted_aisle_hero_banner',
  ImageSectionBannerSmall: 'image_section_banner_small',
  ImageVideoThumbnail: 'image_video_thumbnail',
  MnmPrototypeVideoAssetThumbnail: 'mnm.prototype.video_asset_thumbnail',
  MnmPrototypeVideoAssetVideoAd: 'mnm.prototype.video_asset_video_ad',
  PsdExample: 'psd_example',
  Video: 'video',
} as const
export type GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssetsTypeEnum =
  typeof GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssetsTypeEnum[keyof typeof GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssetsTypeEnum]

/**
 * Check if a given object implements the GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets interface.
 */
export function instanceOfGetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'uploadedUri' in value
  isInstance = isInstance && 'altText' in value

  return isInstance
}

export function GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssetsFromJSON(
  json: any
): GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets {
  return GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssetsFromJSONTyped(json, false)
}

export function GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    type: json['type'],
    previewUrl: !exists(json, 'preview_url') ? undefined : json['preview_url'],
    uploadedUri: json['uploaded_uri'],
    altText: json['alt_text'],
    uploadedFileName: !exists(json, 'uploaded_file_name') ? undefined : json['uploaded_file_name'],
  }
}

export function GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssetsToJSON(
  value?: GetPrototypeCampaignsIdParamDataAttributesAdGroupAttributesAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    preview_url: value.previewUrl,
    uploaded_uri: value.uploadedUri,
    alt_text: value.altText,
    uploaded_file_name: value.uploadedFileName,
  }
}
