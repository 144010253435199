// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeActions } from './AdminDisplayAdGroupResponseDataAttributesCreativeActions'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeActionsToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeActions'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeAssets } from './AdminDisplayAdGroupResponseDataAttributesCreativeAssets'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativeAssetsToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeAssets'
import type { AdminDisplayAdGroupResponseDataAttributesCreativeProperties } from './AdminDisplayAdGroupResponseDataAttributesCreativeProperties'
import {
  AdminDisplayAdGroupResponseDataAttributesCreativePropertiesFromJSON,
  AdminDisplayAdGroupResponseDataAttributesCreativePropertiesFromJSONTyped,
  AdminDisplayAdGroupResponseDataAttributesCreativePropertiesToJSON,
} from './AdminDisplayAdGroupResponseDataAttributesCreativeProperties'

/**
 *
 * @export
 * @interface AdminDisplayAdGroupResponseDataAttributesCreative
 */
export interface AdminDisplayAdGroupResponseDataAttributesCreative {
  /**
   * The id of the creative. Only needed for update
   * @type {string}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreative
   */
  id?: string
  /**
   * The type of placement the creative will be placed in
   * @type {string}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreative
   */
  type: AdminDisplayAdGroupResponseDataAttributesCreativeTypeEnum
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeProperties}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreative
   */
  properties?: AdminDisplayAdGroupResponseDataAttributesCreativeProperties
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeActions}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreative
   */
  actions?: AdminDisplayAdGroupResponseDataAttributesCreativeActions
  /**
   * Product IDs that are used for view through attribution
   * @type {Array<string>}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreative
   */
  trackedProductIds?: Array<string>
  /**
   * Read only field indicating whether creative is using new view through attribution
   * @type {boolean}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreative
   */
  useNewAttribution?: boolean
  /**
   * Product Group IDs that are used for view through attribution based on product group
   * @type {Array<string>}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreative
   */
  trackedProductGroupIds?: Array<string>
  /**
   *
   * @type {AdminDisplayAdGroupResponseDataAttributesCreativeAssets}
   * @memberof AdminDisplayAdGroupResponseDataAttributesCreative
   */
  assets: AdminDisplayAdGroupResponseDataAttributesCreativeAssets
}

/**
 * @export
 */
export const AdminDisplayAdGroupResponseDataAttributesCreativeTypeEnum = {
  CaperImageBannerV1: 'caper_image_banner.v1',
  CaperSpotlightAdPrototypeV1: 'caper_spotlight_ad.prototype.v1',
  EmailV1: 'email.v1',
  ImageBannerV2: 'image_banner.v2',
  ImageBannerV3: 'image_banner.v3',
  MnmVideoPrototypeV1: 'mnm.video.prototype.v1',
  OccasionV1: 'occasion.v1',
  PromotedAisleV1: 'promoted_aisle.v1',
  PromotedAisleV2: 'promoted_aisle.v2',
  PromotedAisleV3: 'promoted_aisle.v3',
  PromotedAisleV4: 'promoted_aisle.v4',
  SearchBannerV1: 'search_banner.v1',
  ShoppableVideoV1: 'shoppable_video.v1',
  ShoppableVideoV2: 'shoppable_video.v2',
  ShoppableVideoV3: 'shoppable_video.v3',
  SponsoredRecipeV1: 'sponsored_recipe.v1',
  SponsoredRecipeVideoV1: 'sponsored_recipe_video.v1',
} as const
export type AdminDisplayAdGroupResponseDataAttributesCreativeTypeEnum =
  typeof AdminDisplayAdGroupResponseDataAttributesCreativeTypeEnum[keyof typeof AdminDisplayAdGroupResponseDataAttributesCreativeTypeEnum]

/**
 * Check if a given object implements the AdminDisplayAdGroupResponseDataAttributesCreative interface.
 */
export function instanceOfAdminDisplayAdGroupResponseDataAttributesCreative(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'assets' in value

  return isInstance
}

export function AdminDisplayAdGroupResponseDataAttributesCreativeFromJSON(
  json: any
): AdminDisplayAdGroupResponseDataAttributesCreative {
  return AdminDisplayAdGroupResponseDataAttributesCreativeFromJSONTyped(json, false)
}

export function AdminDisplayAdGroupResponseDataAttributesCreativeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdminDisplayAdGroupResponseDataAttributesCreative {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    type: json['type'],
    properties: !exists(json, 'properties')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativePropertiesFromJSON(json['properties']),
    actions: !exists(json, 'actions')
      ? undefined
      : AdminDisplayAdGroupResponseDataAttributesCreativeActionsFromJSON(json['actions']),
    trackedProductIds: !exists(json, 'tracked_product_ids')
      ? undefined
      : json['tracked_product_ids'],
    useNewAttribution: !exists(json, 'use_new_attribution')
      ? undefined
      : json['use_new_attribution'],
    trackedProductGroupIds: !exists(json, 'tracked_product_group_ids')
      ? undefined
      : json['tracked_product_group_ids'],
    assets: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsFromJSON(json['assets']),
  }
}

export function AdminDisplayAdGroupResponseDataAttributesCreativeToJSON(
  value?: AdminDisplayAdGroupResponseDataAttributesCreative | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    properties: AdminDisplayAdGroupResponseDataAttributesCreativePropertiesToJSON(value.properties),
    actions: AdminDisplayAdGroupResponseDataAttributesCreativeActionsToJSON(value.actions),
    tracked_product_ids: value.trackedProductIds,
    use_new_attribution: value.useNewAttribution,
    tracked_product_group_ids: value.trackedProductGroupIds,
    assets: AdminDisplayAdGroupResponseDataAttributesCreativeAssetsToJSON(value.assets),
  }
}
