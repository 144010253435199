// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { CoreIngredientsResponse } from '../models/CoreIngredientsResponse'
import {
  CoreIngredientsResponseFromJSON,
  CoreIngredientsResponseToJSON,
} from '../models/CoreIngredientsResponse'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { RecipeProductsResponse } from '../models/RecipeProductsResponse'
import {
  RecipeProductsResponseFromJSON,
  RecipeProductsResponseToJSON,
} from '../models/RecipeProductsResponse'

export interface GetRecipesIdProductsRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetRecipesSearchCoreIngredientsRequest {
  searchTerm: string
  xApiKey?: string
  xSecretKey?: string
}

/**
 *
 */
export class RecipesApi extends runtime.BaseAPI {
  /**
   * Endpoint for retrieving a recipe.
   * Get the products for the given recipe id.
   */
  async getRecipesIdProductsRaw(
    requestParameters: GetRecipesIdProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RecipeProductsResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getRecipesIdProducts.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/recipes/{id}/products`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      RecipeProductsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Endpoint for retrieving a recipe.
   * Get the products for the given recipe id.
   */
  async getRecipesIdProducts(
    requestParameters: GetRecipesIdProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RecipeProductsResponse> {
    const response = await this.getRecipesIdProductsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Endpoint for retrieving a recipe.
   * Get the core ingredients for the given search term.
   */
  async getRecipesSearchCoreIngredientsRaw(
    requestParameters: GetRecipesSearchCoreIngredientsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CoreIngredientsResponse>> {
    if (requestParameters.searchTerm === null || requestParameters.searchTerm === undefined) {
      throw new runtime.RequiredError(
        'searchTerm',
        'Required parameter requestParameters.searchTerm was null or undefined when calling getRecipesSearchCoreIngredients.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.searchTerm !== undefined) {
      queryParameters['search_term'] = requestParameters.searchTerm
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/recipes/search_core_ingredients`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      CoreIngredientsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Endpoint for retrieving a recipe.
   * Get the core ingredients for the given search term.
   */
  async getRecipesSearchCoreIngredients(
    requestParameters: GetRecipesSearchCoreIngredientsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CoreIngredientsResponse> {
    const response = await this.getRecipesSearchCoreIngredientsRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
