import { getEnv } from 'common/utils'
import { GetAdminAccountsResponseDataAttributesAccountTypeEnum as AccountTypes } from 'service/openapi/__codegen__/models/GetAdminAccountsResponseDataAttributes'

export const PROVI_ADS_TERMS_URL = 'https://app.provi.com/terms/ads'
export const PROVI_PRIVACY_URL = 'https://app.provi.com/privacy'
export const PROVI_SELF_SERVE_TERMS_URL = 'https://app.provi.com/terms/selfserveads'

export const constants = {
  images: {
    placeholder: '/images/placeholders/image_placeholder.png',
  },
  fontWeight: {
    semiBold: 600,
  },
}

export const VISIBILITY_DATE = '2022-01-01'
export const VISIBILITY_GROUP_ONE_DATE = '2021-09-01'
export const VISIBILITY_GROUP_CANADA_DATE = '2020-06-01'
export const VISIBILITY_METRIC_CHANGE_HELP_CENTER_LINK =
  'https://instacart-ads.knowledgeowl.com/help/change-to-impression-reporting-for-featured-product'

export const MTA_RELEASE_DATE = getEnv('REPORTING_MTA_CHANGE_DATE') || '2022-08-01'
export const SUAS_RELEASE_DATE = '2023-01-01'
export const MTA_HELP_CENTER_LINK =
  'https://instacart-ads.knowledgeowl.com/help/linear-attribution-reporting'
export const ADS_API_PATH = 'api/v2'
export const ADS_API_URL = getEnv('ADS_API_BASE_URL') + ADS_API_PATH

export const SHOPPABLE_DISPLAY_HELP_CENTER_LINK =
  'https://instacart-ads.knowledgeowl.com/help/creating-display-ad-groups#anchortwo'

export const NEW_TO_BRAND_RELEASE_DATE =
  getEnv('REPORTING_NEW_TO_BRAND_CHANGE_DATE') || '2022-08-01'
export const NEW_TO_BRAND_HELP_CENTER_LINK = 'https://www.todo.com'

export const ENTERPRISE_REQUESTS_LANDING_PAGE_URL = 'https://enterprise-servicedesk.instacart.com/'

export const SPONSORED_PRODUCT_BIDDING_HELP_CENTER_LINK =
  'https://instacart-ads.knowledgeowl.com/help/sponsored-product-bidding'

// To be updated when the help page is created
export const SDS_HELP_CENTER_LINK = 'https://instacart-ads.knowledgeowl.com/help'

export const rtdRsdAccountTypes: AccountTypes[] = [
  AccountTypes.AdvertiserRtd,
  AccountTypes.RetailerRsd,
]

export const rtdRsdRetailerAccountTypes: AccountTypes[] = [
  ...rtdRsdAccountTypes,
  AccountTypes.Retailer,
]
