// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb'
import type { PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsVideo } from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsVideo'
import {
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsVideoFromJSON,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsVideoFromJSONTyped,
  PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsVideoToJSON,
} from './PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsVideo'

/**
 *
 * @export
 * @interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets
 */
export interface PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets {
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  topImageWeb?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  imageWeb?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  imageMobile?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  topImageMobile?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  squareImageMobile?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  heroImage?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  brandLogo?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsVideo}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  video?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsVideo
  /**
   *
   * @type {PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail}
   * @memberof PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  thumbnail?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail
}

/**
 * Check if a given object implements the PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets interface.
 */
export function instanceOfPostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSON(
  json: any
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets {
  return PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSONTyped(
    json,
    false
  )
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    topImageWeb: !exists(json, 'top_image_web')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebFromJSON(
          json['top_image_web']
        ),
    imageWeb: !exists(json, 'image_web')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebFromJSON(
          json['image_web']
        ),
    imageMobile: !exists(json, 'image_mobile')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileFromJSON(
          json['image_mobile']
        ),
    topImageMobile: !exists(json, 'top_image_mobile')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileFromJSON(
          json['top_image_mobile']
        ),
    squareImageMobile: !exists(json, 'square_image_mobile')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileFromJSON(
          json['square_image_mobile']
        ),
    heroImage: !exists(json, 'hero_image')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageFromJSON(
          json['hero_image']
        ),
    brandLogo: !exists(json, 'brand_logo')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoFromJSON(
          json['brand_logo']
        ),
    video: !exists(json, 'video')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsVideoFromJSON(
          json['video']
        ),
    thumbnail: !exists(json, 'thumbnail')
      ? undefined
      : PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailFromJSON(
          json['thumbnail']
        ),
  }
}

export function PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsToJSON(
  value?: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    top_image_web:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebToJSON(
        value.topImageWeb
      ),
    image_web:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebToJSON(
        value.imageWeb
      ),
    image_mobile:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileToJSON(
        value.imageMobile
      ),
    top_image_mobile:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileToJSON(
        value.topImageMobile
      ),
    square_image_mobile:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileToJSON(
        value.squareImageMobile
      ),
    hero_image:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageToJSON(
        value.heroImage
      ),
    brand_logo:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoToJSON(
        value.brandLogo
      ),
    video: PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsVideoToJSON(
      value.video
    ),
    thumbnail:
      PostVideoCampaignsIdSubmitParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailToJSON(
        value.thumbnail
      ),
  }
}
