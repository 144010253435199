// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayAdGroupsParamDataAttributesCreativeAssetsBrandLogo } from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsBrandLogo'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsBrandLogoFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsBrandLogoFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsBrandLogoToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsBrandLogo'
import type { GetDisplayAdGroupsParamDataAttributesCreativeAssetsHeroImage } from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsHeroImage'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsHeroImageFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsHeroImageFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsHeroImageToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsHeroImage'
import type { GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageMobile } from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageMobile'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageMobileFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageMobileFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageMobileToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageMobile'
import type { GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageWeb } from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageWeb'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageWebFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageWebFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageWebToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageWeb'
import type { GetDisplayAdGroupsParamDataAttributesCreativeAssetsSquareImageMobile } from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsSquareImageMobile'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsSquareImageMobileFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsSquareImageMobileFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsSquareImageMobileToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsSquareImageMobile'
import type { GetDisplayAdGroupsParamDataAttributesCreativeAssetsThumbnail } from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsThumbnail'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsThumbnailFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsThumbnailFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsThumbnailToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsThumbnail'
import type { GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageMobile } from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageMobile'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageMobileFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageMobileFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageMobileToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageMobile'
import type { GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageWeb } from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageWeb'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageWebFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageWebFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageWebToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageWeb'
import type { GetDisplayAdGroupsParamDataAttributesCreativeAssetsVideo } from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsVideo'
import {
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsVideoFromJSON,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsVideoFromJSONTyped,
  GetDisplayAdGroupsParamDataAttributesCreativeAssetsVideoToJSON,
} from './GetDisplayAdGroupsParamDataAttributesCreativeAssetsVideo'

/**
 *
 * @export
 * @interface GetDisplayAdGroupsParamDataAttributesCreativeAssets
 */
export interface GetDisplayAdGroupsParamDataAttributesCreativeAssets {
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageWeb}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeAssets
   */
  topImageWeb?: GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageWeb
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageWeb}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeAssets
   */
  imageWeb?: GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageWeb
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageMobile}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeAssets
   */
  imageMobile?: GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageMobile
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageMobile}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeAssets
   */
  topImageMobile?: GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageMobile
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeAssetsSquareImageMobile}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeAssets
   */
  squareImageMobile?: GetDisplayAdGroupsParamDataAttributesCreativeAssetsSquareImageMobile
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeAssetsHeroImage}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeAssets
   */
  heroImage?: GetDisplayAdGroupsParamDataAttributesCreativeAssetsHeroImage
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeAssetsBrandLogo}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeAssets
   */
  brandLogo?: GetDisplayAdGroupsParamDataAttributesCreativeAssetsBrandLogo
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeAssetsVideo}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeAssets
   */
  video?: GetDisplayAdGroupsParamDataAttributesCreativeAssetsVideo
  /**
   *
   * @type {GetDisplayAdGroupsParamDataAttributesCreativeAssetsThumbnail}
   * @memberof GetDisplayAdGroupsParamDataAttributesCreativeAssets
   */
  thumbnail?: GetDisplayAdGroupsParamDataAttributesCreativeAssetsThumbnail
}

/**
 * Check if a given object implements the GetDisplayAdGroupsParamDataAttributesCreativeAssets interface.
 */
export function instanceOfGetDisplayAdGroupsParamDataAttributesCreativeAssets(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetDisplayAdGroupsParamDataAttributesCreativeAssetsFromJSON(
  json: any
): GetDisplayAdGroupsParamDataAttributesCreativeAssets {
  return GetDisplayAdGroupsParamDataAttributesCreativeAssetsFromJSONTyped(json, false)
}

export function GetDisplayAdGroupsParamDataAttributesCreativeAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayAdGroupsParamDataAttributesCreativeAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    topImageWeb: !exists(json, 'top_image_web')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageWebFromJSON(
          json['top_image_web']
        ),
    imageWeb: !exists(json, 'image_web')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageWebFromJSON(json['image_web']),
    imageMobile: !exists(json, 'image_mobile')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageMobileFromJSON(
          json['image_mobile']
        ),
    topImageMobile: !exists(json, 'top_image_mobile')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageMobileFromJSON(
          json['top_image_mobile']
        ),
    squareImageMobile: !exists(json, 'square_image_mobile')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeAssetsSquareImageMobileFromJSON(
          json['square_image_mobile']
        ),
    heroImage: !exists(json, 'hero_image')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeAssetsHeroImageFromJSON(json['hero_image']),
    brandLogo: !exists(json, 'brand_logo')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeAssetsBrandLogoFromJSON(json['brand_logo']),
    video: !exists(json, 'video')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeAssetsVideoFromJSON(json['video']),
    thumbnail: !exists(json, 'thumbnail')
      ? undefined
      : GetDisplayAdGroupsParamDataAttributesCreativeAssetsThumbnailFromJSON(json['thumbnail']),
  }
}

export function GetDisplayAdGroupsParamDataAttributesCreativeAssetsToJSON(
  value?: GetDisplayAdGroupsParamDataAttributesCreativeAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    top_image_web: GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageWebToJSON(
      value.topImageWeb
    ),
    image_web: GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageWebToJSON(value.imageWeb),
    image_mobile: GetDisplayAdGroupsParamDataAttributesCreativeAssetsImageMobileToJSON(
      value.imageMobile
    ),
    top_image_mobile: GetDisplayAdGroupsParamDataAttributesCreativeAssetsTopImageMobileToJSON(
      value.topImageMobile
    ),
    square_image_mobile: GetDisplayAdGroupsParamDataAttributesCreativeAssetsSquareImageMobileToJSON(
      value.squareImageMobile
    ),
    hero_image: GetDisplayAdGroupsParamDataAttributesCreativeAssetsHeroImageToJSON(value.heroImage),
    brand_logo: GetDisplayAdGroupsParamDataAttributesCreativeAssetsBrandLogoToJSON(value.brandLogo),
    video: GetDisplayAdGroupsParamDataAttributesCreativeAssetsVideoToJSON(value.video),
    thumbnail: GetDisplayAdGroupsParamDataAttributesCreativeAssetsThumbnailToJSON(value.thumbnail),
  }
}
